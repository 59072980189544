import { CloudUpload, FileType, LoaderCircle, Trash2 } from "lucide-react";
import { useCallback, useEffect, useRef, useState } from "react";
import useApiRequest from "../../../lib/hooks/useRequest";
import { Button } from "../../../ui/components/ui/button";
import { FileTypeEnum } from "../../../ui/components/ui/file-type";
import { Input } from "../../../ui/components/ui/input";

const DataUpload = ({
  type,
  id,
  onFilesUploaded,
  withUploadButton,
}: {
  type: "internal" | "customer";
  id?: string;
  onFilesUploaded: () => void;
  withUploadButton?: boolean;
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState<
    {
      file: File;
      fileSize: number;
    }[]
  >([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const { apiFileRequest } = useApiRequest();
  const [startedUpload, setStartedUpload] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const uploadFiles = useCallback(async () => {
    if (files.length > 0) {
      setIsUploading(true);
      await apiFileRequest<string>(
        `jobs/${type}/${id}/files`,
        "POST",
        files.map((file) => file.file),
        {
          formdataName: "files",
        }
      );
      setIsUploading(false);
    }
    if (id) {
      onFilesUploaded();
    }
  }, [apiFileRequest, files, id, onFilesUploaded, type]);

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFileDrop(event.dataTransfer.files);
    }
  };

  const handleFileDrop = (files: FileList | null) => {
    if (files?.length === 0 || !files) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    const filteredFiles = Array.from(files).filter((file) => allowedTypes.includes(file.type));
    handleUpload(filteredFiles);
  };

  const handleUploadClick = () => {
    const uploadBtn = document.querySelector(`#uploadBtn`) as HTMLButtonElement | null;
    if (uploadBtn) {
      uploadBtn.click();
    }
  };

  const handleUpload = (files: File[]) => {
    setFiles((prev) => [
      ...prev,
      ...Array.from(files).map((file) => ({
        file,
        fileSize: file.size,
      })),
    ]);
  };

  useEffect(() => {
    if (id && !startedUpload && !withUploadButton) {
      setStartedUpload(true);
      uploadFiles();
    }
  }, [id, startedUpload, uploadFiles, withUploadButton]);

  return (
    <div className="flex flex-col py-7 bg-white rounded-lg h-full">
      <p className="text-[16px] leading-[38px] text-[#888] mx-9">
        <span className="font-bold text-[#12282A]">Dateien hochladen</span> (optional)
      </p>

      <div
        className={`flex flex-col rounded-xl px-6 py-4 border border-[#D0D5DD] text-sm justify-center items-center mx-9 ${
          isDragging ? "border-[#C0DE60] border-dotted" : ""
        }`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleUploadClick}
      >
        <Button
          variant="outline"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleUploadClick();
          }}
        >
          <CloudUpload />
        </Button>
        <p className="text-sm mt-3">
          <span className="text-[#C0DE60] font-medium">Zum Upload klicken</span> oder hier reinziehen
        </p>
        <p className="text-xs">PDF, SVG, PNG, JPG or GIF (max. 100 MB)</p>
        <label id={`uploadBtn`}>
          <Input
            type="file"
            className="hidden"
            multiple
            ref={inputRef}
            onChange={(e) => {
              e.preventDefault();
              if (e.target?.files && e.target?.files.length > 0) {
                const files = Array.from(e.target.files);
                handleUpload(files);
              }
            }}
          />
        </label>
      </div>
      <div className="h-full overflow-hidden">
        <div className="flex flex-col gap-3 rounded-lg text-sm max-h-60 overflow-y-scroll custom-scrollbar ml-9 mr-5 mt-7">
          {files.map((file, index) => (
            <UploadedFile
              key={index}
              name={file.file.name}
              fileSize={file.fileSize}
              onRemove={() => {
                setFiles((prev) => prev.filter((_, i) => i !== index));
              }}
            />
          ))}
        </div>
      </div>
      {withUploadButton && (
        <Button
          className="mt-5 mx-9 flex gap-2"
          onClick={(e) => {
            e.preventDefault();
            uploadFiles();
          }}
          disabled={isUploading}
        >
          {isUploading && <LoaderCircle className="animate-spin" />}
          Upload
        </Button>
      )}
    </div>
  );
};

const UploadedFile = ({ name, fileSize, onRemove }: { name: string; fileSize: number; onRemove: () => void }) => {
  const calculateFileString = (fileSize: number) => {
    const size = fileSize / 1024;
    if (size < 1024) {
      return `${size.toFixed(2)} KB`;
    }
    return `${(size / 1024).toFixed(2)} MB`;
  };

  return (
    <div className="flex justify-between items-center gap-3 rounded-lg px-3 py-2 border border-[#D0D5DD] text-sm mr-2">
      <div className="flex gap-2 items-center">
        <FileType type={FileTypeEnum.PDF} />
        <div className="flex flex-col text-sm justify-between">
          <p className="font-medium">{name}</p>
          <p className="text-xs">{calculateFileString(fileSize)}</p>
        </div>
      </div>
      <Button
        variant="link"
        className="text-red-500"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRemove();
        }}
      >
        <Trash2 size={20} />
      </Button>
    </div>
  );
};

export default DataUpload;
