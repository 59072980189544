import { Paginated } from "@/lib/interfaces/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { CopyIcon } from "@radix-ui/react-icons";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { CheckIcon, Download, ExternalLink, SaveIcon } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../../../lib/hooks/useRequest";
import { convertHtmlToEditorState, downloadURI, formatDateTime, parseStatus } from "../../../../lib/hooks/utils";
import { JobDetailsInternalResponseDto, LogList } from "../../../../lib/interfaces/internal/jobs";
import { Button } from "../../../../ui/components/ui/button";
import DataFetchParent from "../../../../ui/components/ui/data-fetch";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../../ui/components/ui/form";
import { Input } from "../../../../ui/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../ui/components/ui/select";
import { StatusBar } from "../../[id]/page";
import Chat from "../../chat";
import CreateEditor from "../../create/create-editor";
import DataUpload from "../../create/data-upload";
import JobFileList from "../../file-list";
import { Context } from "../../../../components/root/context";
import { StarsRating } from "../../../../ui/components/ui/stars-rating";

export const JobDetailsParent = ({ id }: { id: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<JobDetailsInternalResponseDto>();
  const [reload, setReload] = useState(false);
  const { apiRequest } = useApiRequest();

  const fetchOrder = useCallback(async () => {
    setIsLoading(true);
    const res = await apiRequest<JobDetailsInternalResponseDto>(`jobs/internal/${id}`, "GET");
    setIsLoading(false);
    if (res.data) {
      setOrder(res.data);
    }
  }, [apiRequest, id]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder, reload]);

  if (!id) {
    return <div>Organisation not found</div>;
  }

  return (
    <DataFetchParent
      data={order}
      renderElement={(data) => <OrderDetails orderId={id} setReload={setReload} order={data} />}
      isLoading={isLoading}
    />
  );
};

function OrderDetails({
  order,
  orderId,
  setReload,
}: {
  order: JobDetailsInternalResponseDto;
  orderId: string;
  setReload: Dispatch<SetStateAction<boolean>>;
}) {
  const { apiRequest } = useApiRequest();
  const [logs, setLogs] = useState<LogList[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const { isMobile } = useContext(Context);

  const fetchLogs = useCallback(async () => {
    setIsLoading(true);
    const res = await apiRequest<Paginated<LogList>>(`jobs/internal/${orderId}/logs`, "GET");
    if (res.data) {
      setLogs(res.data.docs);
    }
    setIsLoading(false);
  }, [apiRequest, orderId]);

  useEffect(() => {
    if (order.showLogs) {
      fetchLogs();
    }
  }, [fetchLogs, order.showLogs]);

  const fileUrlSchema = z.object({
    url: z
      .string()
      .trim()
      .refine(
        (val) => {
          return val === "" || z.string().url().safeParse(val).success;
        },
        {
          message: "Ungültige URL",
        }
      ),
  });
  type FileUrlSchema = z.infer<typeof fileUrlSchema>;

  const fileUrlForm = useForm<FileUrlSchema>({
    resolver: zodResolver(fileUrlSchema),
    defaultValues: {
      url: order.finalUrl ?? "",
    },
  });

  const updateFinalUrl = async (data: FileUrlSchema) => {
    const req = {
      finalUrl: data.url === "" ? null : data.url,
    };
    const res = await apiRequest(`jobs/internal/${orderId}/final-url`, "PATCH", {
      body: req,
      toast: {
        toastText: "URL erfolgreich aktualisiert",
      },
    });
    if (res.data) {
      setReload((prev) => !prev);
    }
  };

  const copyToClipboard = (text: string) => {
    setCopied(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <div className="flex flex-col gap-3 h-full">
      <div className={`flex justify-between ${isMobile ? "flex-col gap-2" : ""}`}>
        <div className="grow">
          <div className={`flex gap-4 items-center ${isMobile ? "flex-col gap-2" : ""}`}>
            <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">
              {order.jobIncrementId} {order.title}
            </h1>
            {order.rating && <StarsRating starAmount={order.rating} />}
          </div>
          <p className="text-[16px] text-[#888]">Ansprechpartner: {order.belongsToCustomerUserFullName}</p>
          <p className="text-[16px] text-[#888]">Kunde: {order.belongsToOrganizationName}</p>
        </div>
        <div>
          <StatusUpdate order={order} orderId={orderId} setReload={setReload} />
        </div>
      </div>
      <StatusBar status={order.status} />
      <div className={`flex gap-6 ${isMobile ? "flex-col" : "h-[70vh]"}`}>
        <div className={`flex flex-col ${isMobile ? "w-full" : "w-1/2"} gap-4 h-full`}>
          <Form {...fileUrlForm}>
            <form
              onSubmit={fileUrlForm.handleSubmit(updateFinalUrl)}
              className="flex flex-col bg-[#12282A] rounded-[14px] p-6 gap-2"
            >
              <FormField
                control={fileUrlForm.control}
                name="url"
                render={({ field }) => (
                  <FormItem className={`${isMobile ? "flex flex-col" : ""}`}>
                    <div className={`${isMobile ? "flex flex-col gap-1" : "flex justify-between"}`}>
                      <FormLabel className="text-[16px] font-bold text-white">
                        Link zu den finalen Dateien* <br />
                        (muss vorhanden sein um Status abzuschließen)
                      </FormLabel>
                      {!isMobile && (
                        <Button type="submit" variant="outline" className="flex gap-2 w-fit self-end">
                          <SaveIcon />
                        </Button>
                      )}
                    </div>
                    <FormControl className="bg-white px-5 py-2 rounded-2xl flex items-center">
                      <div className="flex gap-2">
                        <Input {...field} placeholder="Link zu den finalen Dateien" />
                        {copied ? (
                          <CheckIcon className="h-5 w-5" />
                        ) : (
                          <CopyIcon onClick={() => copyToClipboard(field.value)} className="cursor-pointer h-5 w-5" />
                        )}
                        <ExternalLink
                          size={20}
                          onClick={() => window.open(field.value, "_blank")}
                          className="cursor-pointer"
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                    {isMobile && (
                      <Button type="submit" variant="outline" className="flex gap-2 w-fit self-end">
                        <SaveIcon />
                      </Button>
                    )}
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <div className="bg-white rounded-[14px] py-3 pr-3 flex-grow overflow-hidden">
            <div className="flex flex-col p-6 gap-3 custom-scrollbar h-full overflow-auto">
              <DescriptionManager orderId={orderId} order={order} />
              <JobFileList files={order.files} id={orderId} type="internal" setReload={setReload} />
            </div>
          </div>
        </div>
        <Chat type="internal" id={orderId} />
      </div>
      <div className="">
        <InternalNotes order={order} orderId={orderId} setReload={setReload} />
      </div>
      {order.showLogs && (
        <DataFetchParent
          data={logs}
          renderElement={(data) => <Logs logs={data} id={orderId} />}
          isLoading={isLoading}
        />
      )}
      {order.filesAllowed && (
        <DataUpload
          type="internal"
          onFilesUploaded={() => setReload((prev) => !prev)}
          id={orderId}
          withUploadButton={true}
        />
      )}
    </div>
  );
}

const DescriptionManager = ({ orderId, order }: { orderId: string; order: JobDetailsInternalResponseDto }) => {
  const { apiRequest } = useApiRequest();

  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(order.description));
  const formSchema = z.object({
    description: z.string().refine(
      () => {
        const htmlContentProfile = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        return htmlContentProfile.length > 2;
      },
      { message: "Beschreibung ist erforderlich" }
    ),
  });

  type FormSchema = z.infer<typeof formSchema>;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      description: "",
    },
  });
  const onSubmit = async (data: FormSchema) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    const req = {
      description: htmlContent,
    };
    await apiRequest(`jobs/internal/${orderId}/description`, "PATCH", {
      body: req,
      toast: {
        toastText: "Beschreibung erfolgreich editiert",
      },
    });
  };

  return (
    <>
      <p className="text-[#12282A] font-bold">Auftragsbeschreibung</p>
      {order.descriptionEditable ? (
        <div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full">
              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem className="">
                    <div className="w-full">
                      <FormControl>
                        <CreateEditor editorState={editorState} setEditorState={setEditorState} />
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="flex gap-4 w-fit self-end" variant="outline">
                Aktualisieren <SaveIcon />
              </Button>
            </form>
          </Form>
        </div>
      ) : (
        <div className="bg-white mb-8 rounded-2xl flex items-center justify-betweentext-[#888]">
          <div className="w-full resize-none custom-scrollbar pr-2 outline-none disabled:bg-white">
            <CreateEditor disabled={true} editorState={editorState} />
          </div>
        </div>
      )}
    </>
  );
};

const InternalNotes = ({
  order,
  orderId,
  setReload,
}: {
  order: JobDetailsInternalResponseDto;
  orderId: string;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiRequest } = useApiRequest();
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(order.internalNotes ?? ""));
  const { isMobile } = useContext(Context);

  const internalNotesSchema = z.object({
    internalNotes: z.string().refine(
      () => {
        const htmlContentProfile = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        return htmlContentProfile.length > 2;
      },
      { message: "Notizen sind erforderlich" }
    ),
  });
  type InternalNotesSchema = z.infer<typeof internalNotesSchema>;

  const internalNotesForm = useForm<InternalNotesSchema>({
    resolver: zodResolver(internalNotesSchema),
    defaultValues: {
      internalNotes: order.internalNotes ?? "",
    },
  });

  const updateInternalNotes = async (data: InternalNotesSchema) => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    const req = {
      internalNotes: htmlContent,
    };
    const res = await apiRequest(`jobs/internal/${orderId}/internal-notes`, "PATCH", {
      body: req,
      toast: {
        toastText: "Notizen erfolgreich aktualisiert",
      },
    });
    if (res.data) {
      setReload((prev) => !prev);
    }
  };

  return (
    <Form {...internalNotesForm}>
      <form
        onSubmit={internalNotesForm.handleSubmit(updateInternalNotes)}
        className="flex flex-col bg-white rounded-[14px] p-6 gap-3 h-full"
      >
        <FormField
          control={internalNotesForm.control}
          name="internalNotes"
          render={({ field }) => (
            <FormItem className={`flex flex-col ${isMobile ? "" : "py-7 px-9"} bg-white rounded-lg gap-[14px]`}>
              <FormLabel className="text-[16px] font-bold text-[#12282A]">Interne Notizen</FormLabel>
              <div className="w-full">
                <FormControl>
                  <CreateEditor editorState={editorState} setEditorState={setEditorState} />
                </FormControl>
              </div>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" variant="outline" className="flex gap-2 w-fit self-end">
          <SaveIcon />
          <span>Aktualisieren</span>
        </Button>
      </form>
    </Form>
  );
};

const StatusUpdate = ({
  order,
  orderId,
  setReload,
}: {
  order: JobDetailsInternalResponseDto;
  orderId: string;
  setReload: Dispatch<SetStateAction<boolean>>;
}) => {
  const { apiRequest } = useApiRequest();
  const [isOpen, setIsOpen] = useState(false);

  const statusSchema = z
    .object({
      status: z.string(),
      dueDate: z.string().optional(),
      message: z.string().optional(),
    })
    .refine(
      (data) => {
        if (order.needsDueDateAndMessageOnStatusChangeToActive && !data.dueDate && data.status === "ACTIVE") {
          return false;
        }
        return true;
      },
      {
        message: "Fälligkeitsdatum ist erforderlich",
        path: ["dueDate"],
      }
    )
    .refine(
      (data) => {
        if (order.needsDueDateAndMessageOnStatusChangeToActive && !data.message && data.status === "ACTIVE") {
          return false;
        }
        return true;
      },
      {
        message: "Eine Nachricht ist erforderlich",
        path: ["message"],
      }
    );
  type StatusSchema = z.infer<typeof statusSchema>;

  const statusForm = useForm<StatusSchema>({
    resolver: zodResolver(statusSchema),
    defaultValues: {
      dueDate: order.dueDate ?? "",
      message: "",
    },
  });

  const updateStatus = async (data: StatusSchema) => {
    if (data.status === order.status) return;
    const req: {
      status: string;
      dueDate?: Date | string;
      message?: string;
    } = {
      status: data.status,
    };
    if (order.needsDueDateAndMessageOnStatusChangeToActive && data.status === "ACTIVE") {
      req.dueDate = data.dueDate ?? new Date();
      req.message = data.message ?? "";
    }
    const res = await apiRequest(`jobs/internal/${orderId}/status`, "PATCH", {
      body: req,
      toast: {
        toastText: "Status erfolgreich aktualisiert",
      },
    });
    if (res.data) {
      setReload((prev) => !prev);
    }
  };

  return (
    <Form {...statusForm}>
      <form onSubmit={statusForm.handleSubmit(updateStatus)} className="flex gap-2">
        <FormField
          control={statusForm.control}
          name="status"
          render={({ field }) => (
            <FormItem className="flex flex-col h-full rounded-lg gap-[14px]">
              <FormControl>
                <Select
                  onValueChange={(value) => {
                    field.onChange(value);
                    if (!order.needsDueDateAndMessageOnStatusChangeToActive || value !== "ACTIVE") {
                      statusForm.handleSubmit(updateStatus)();
                    } else {
                      setIsOpen(true);
                    }
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger
                      className="gap-3 h-10 items-center rounded-2xl px-3 py-2 border text-sm inline-flex justify-center whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground"
                      selectIconVariant="styled"
                    >
                      <SelectValue placeholder={"Status ändern"} />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {order.selectableEntities.status.map((status) => (
                      <SelectItem key={status} value={status}>
                        {parseStatus(status)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Dialog
          open={isOpen}
          onOpenChange={(open) => {
            setIsOpen(open);
            if (!open) {
              statusForm.setValue("dueDate", "");
              statusForm.setValue("status", "");
            }
          }}
        >
          <DialogContent className="z-50">
            <DialogHeader>
              <DialogTitle>Fälligkeitsdatum & Nachricht</DialogTitle>
            </DialogHeader>
            <div className="flex gap-2">
              <div className="grow w-1/2">
                <FormField
                  control={statusForm.control}
                  name="dueDate"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input
                          {...field}
                          type="date"
                          placeholder="Fälligkeitsdatum"
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="grow w-1/2">
                <FormField
                  control={statusForm.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="Nachricht" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <DialogFooter>
              <div className={`flex gap-3 w-full`}>
                <Button type="submit" onClick={() => statusForm.handleSubmit(updateStatus)()}>
                  Speichern
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </form>
    </Form>
  );
};

const Logs = ({ logs, id }: { logs: LogList[]; id: string }) => {
  const { apiRequest } = useApiRequest();

  const fetchPdf = async () => {
    const res = await apiRequest<{ fileUrl: string }>(`jobs/internal/${id}/logs/pdf`, "GET");
    if (res.data) {
      downloadURI(res.data.fileUrl, "logs.pdf");
    }
  };

  return (
    <div className="flex flex-col gap-4 bg-white rounded-2xl">
      <div>
        <div className="bg-[#12282A] rounded-t-[14px] p-6 flex justify-between">
          <p className="text-white text-2xl font-bold">Logs</p>
          <div>
            <Button variant="outline" onClick={fetchPdf} className="gap-2">
              <Download size={20} />
              Logs als PDF herunterladen
            </Button>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 p-6">
        {logs.map((log, i) => (
          <div key={i} className="flex gap-2 items-center rounded-2xl border p-2">
            <div className="flex flex-col w-full gap-2">
              <div className="flex justify-between items-center">
                <span className="font-medium text-sm">{log.belongsToUserFullName}</span>
              </div>
              <div className="flex justify-between border rounded-2xl items-center px-4 py-2">
                <p className="text-sm">{log.message}</p>
                <span className="text-xs">{formatDateTime(log.createdAt)}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
