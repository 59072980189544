import { Check, Copy } from "lucide-react";
import { useEffect, useState } from "react";
import GeneralMessagesIcon from "../../ui/components/icons/general-messages-icon";
import OtherMessagesIcon from "../../ui/components/icons/other-messages-icon";
import ProblemSolvingIcon from "../../ui/components/icons/problem-solving-icon";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../ui/components/ui/accordion";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/components/ui/tabs";

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

const BestPractices = () => {
  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex justify-between">
        <div>
          <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">7i7 Leitfaden</h1>
          <p className="text-[16px] text-[#888]">Ansätze & Hilfe für deinen Alltag</p>
        </div>
      </div>

      <Tabs defaultValue="general" className="w-full">
        <TabsList className="gap-4">
          <TabsTrigger value="general">
            <GeneralMessagesIcon />
            Allgemeine Nachrichten
          </TabsTrigger>
          <TabsTrigger value="problem-solving">
            <ProblemSolvingIcon />
            Problembehebungs Nachrichten
          </TabsTrigger>
          <TabsTrigger value="other-solutions">
            <OtherMessagesIcon />
            Sonstige Nachrichten
          </TabsTrigger>
        </TabsList>
        <TabsContent value="general">
          <GeneralMessagesTab />
        </TabsContent>
        <TabsContent value="problem-solving">
          <ProblemSolvingMessagesTab />
        </TabsContent>
        <TabsContent value="other-solutions">
          <OtherMessagesTab />
        </TabsContent>
      </Tabs>
    </div>
  );
};

const GeneralMessagesTab = () => {
  const [firstMessageCopied, setFirstMessageCopied] = useState(false);
  const [secondMessageCopied, setSecondMessageCopied] = useState(false);
  const [thirdMessageCopied, setThirdMessageCopied] = useState(false);

  useEffect(() => {
    if (firstMessageCopied) {
      setTimeout(() => {
        setFirstMessageCopied(false);
      }, 2000);
    }
  }, [firstMessageCopied]);

  useEffect(() => {
    if (secondMessageCopied) {
      setTimeout(() => {
        setSecondMessageCopied(false);
      }, 2000);
    }
  }, [secondMessageCopied]);

  useEffect(() => {
    if (thirdMessageCopied) {
      setTimeout(() => {
        setThirdMessageCopied(false);
      }, 2000);
    }
  }, [thirdMessageCopied]);

  const firstMessage = {
    title: "Erster Entwurf",
    content:
      "Moin,\n\n wir haben die ersten Entwürfe für Dein Design fertiggestellt und sind gespannt, was Du dazu sagst! Schau sie Dir gerne in Ruhe an und gib uns Dein Feedback. So können wir direkt sehen, ob wir in die richtige Richtung gehen oder noch Anpassungen vornehmen sollen. \n\n Wir freuen uns auf Deine Rückmeldung und melden uns bald wieder mit dem nächsten Update! \n\n Viele Grüße aus dem Norden",
  };

  const secondMessage = {
    title: "Zweiter Entwurf",
    content:
      "Moin, \n\n wir haben die letzten Anpassungen an deinem Design umgesetzt und denken, dass wir jetzt nah an deinem Wunsch-Ergebnis sind. \n\n Schau es Dir gerne an, und gib mir Bescheid, ob es so für Dich passt oder wir noch etwas Feinschliff vornehmen sollen. \n\n Wir freuen uns auf Dein Feedback! \n\n Viele Grüße aus dem Norden",
  };

  const thirdMessage = {
    title: "Auftrag abgeschlossen",
    content:
      "Moin, \n\n Dein Auftrag ist nun abgeschlossen. \n\n Sollten im Nachhinein noch Änderungen oder Fragen auftreten, kannst Du den Auftrag gerne in Revision setzen. Melde dich einfach, wenn Du noch etwas anpassen lassen möchtest. \n\n Wir freuen uns auf die nächsten Projekte! \n\n Viele Grüße aus dem Norden",
  };

  return (
    <Accordion type="single" defaultValue="item-1" collapsible className="w-full gap-3 flex flex-col mt-12">
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{firstMessage.title}</p>
            {firstMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(firstMessage.content);
                  setFirstMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {firstMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{secondMessage.title}</p>
            {secondMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(secondMessage.content);
                  setSecondMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {secondMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{thirdMessage.title}</p>
            {thirdMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(thirdMessage.content);
                  setThirdMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {thirdMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const ProblemSolvingMessagesTab = () => {
  const [firstMessageCopied, setFirstMessageCopied] = useState(false);
  const [secondMessageCopied, setSecondMessageCopied] = useState(false);
  const [thirdMessageCopied, setThirdMessageCopied] = useState(false);
  const [fourthMessageCopied, setFourthMessageCopied] = useState(false);

  useEffect(() => {
    if (firstMessageCopied) {
      setTimeout(() => {
        setFirstMessageCopied(false);
      }, 2000);
    }
  }, [firstMessageCopied]);

  useEffect(() => {
    if (secondMessageCopied) {
      setTimeout(() => {
        setSecondMessageCopied(false);
      }, 2000);
    }
  }, [secondMessageCopied]);

  useEffect(() => {
    if (thirdMessageCopied) {
      setTimeout(() => {
        setThirdMessageCopied(false);
      }, 2000);
    }
  }, [thirdMessageCopied]);

  useEffect(() => {
    if (fourthMessageCopied) {
      setTimeout(() => {
        setFourthMessageCopied(false);
      }, 2000);
    }
  }, [fourthMessageCopied]);

  const firstMessage = {
    title: "Erster Entwurf gefällt nicht",
    content:
      "Moin, \n\n danke für dein Feedback zu den Entwürfen! \n\n Wir werden im nächsten Schritt das Feedback von Dir in einem weiteren Entwurf umsetzen und verbessern. Wollen wir hierzu ein kurzes Meeting machen, um über das Feedback noch einmal persönlich zu sprechen? \n\n Viele Grüße aus dem Norden",
  };

  const secondMessage = {
    title: "Input fehlt",
    content:
      "Moin, \n\n Wir wollten uns kurz melden, da wir noch auf ein paar Infos von Dir warten, um mit dem Auftrag weiterzumachen. \n\n Sobald wir Deinen Input haben, legen wir direkt los und bringen das Design für Dich weiter voran. Falls du noch Fragen hast oder Unterstützung benötigst, sind wir natürlich jederzeit für Dich da! \n\n Viele Grüße aus dem Norden",
  };

  const thirdMessage = {
    title: "Feedback Nachfrage",
    content:
      "Moin, \n\n wir wollten kurz nachfragen, ob du schon Gelegenheit hattest, die Entwürfe anzusehen. Sobald wir Dein Feedback haben, können wir mit den nächsten Schritten loslegen und das Design weiter für Dich optimieren. \n\n Lass uns gerne wissen, falls noch Fragen offen sind oder wir unterstützen können! \n\n Viele Grüße aus dem Norden",
  };

  const fourthMessage = {
    title: "2. Feedback Nachfrage",
    content:
      "Moin, \n\n wir wollten nochmal kurz nachhaken, ob Du schon Zeit hattest, die Entwürfe anzuschauen. Mit deinem Feedback können wir direkt weiter durchstarten! \n\n Melde Dich gern, wenn etwas unklar ist oder du noch Fragen hast. \n\n Viele Grüße aus dem Norden",
  };

  return (
    <Accordion type="single" defaultValue="item-1" collapsible className="w-full gap-3 flex flex-col mt-12">
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{firstMessage.title}</p>
            {firstMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(firstMessage.content);
                  setFirstMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {firstMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{secondMessage.title}</p>
            {secondMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(secondMessage.content);
                  setSecondMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {secondMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-3">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{thirdMessage.title}</p>
            {thirdMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(thirdMessage.content);
                  setThirdMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {thirdMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-4">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{fourthMessage.title}</p>
            {fourthMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(fourthMessage.content);
                  setFourthMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {fourthMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

const OtherMessagesTab = () => {
  const [firstMessageCopied, setFirstMessageCopied] = useState(false);
  const [secondMessageCopied, setSecondMessageCopied] = useState(false);

  useEffect(() => {
    if (firstMessageCopied) {
      setTimeout(() => {
        setFirstMessageCopied(false);
      }, 2000);
    }
  }, [firstMessageCopied]);

  useEffect(() => {
    if (secondMessageCopied) {
      setTimeout(() => {
        setSecondMessageCopied(false);
      }, 2000);
    }
  }, [secondMessageCopied]);

  const firstMessage = {
    title: "Termin vereinbaren",
    content:
      "Moin, \n\n damit wir alle Details zu deinem Auftrag persönlich besprechen können, schlage ich vor, dass wir uns für einen kurzen Termin zusammensetzen. \n\n Hier ist der Link zu meinem Kalender: [Link einfügen] \n\n Dort kannst du dir ganz einfach einen passenden Zeitpunkt aussuchen. Solltest Du hierzu Fragen haben, melde Dich gern! \n\n Viele Grüße aus dem Norden",
  };

  const secondMessage = {
    title: "Plattform Bug",
    content:
      "Moin, \n\n vielen Dank, dass du uns auf die Bugs in unserer Plattform hingewiesen hast. Wir verstehen, dass das frustrierend sein kann, und entschuldigen uns für die Unannehmlichkeiten. Unser Team arbeitet bereits daran, das Problem zu beheben und wir informieren dich, sobald alles wieder reibungslos funktioniert. \n\n Falls dir noch weitere Fehler auffallen, lass es uns gerne wissen – dein Feedback hilft uns, die Plattform weiter zu verbessern. \n\n Vielen Dank für dein Verständnis! \n\n Viele Grüße aus dem Norden",
  };

  return (
    <Accordion type="single" defaultValue="item-1" collapsible className="w-full gap-3 flex flex-col mt-12">
      <AccordionItem value="item-1">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{firstMessage.title}</p>
            {firstMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(firstMessage.content);
                  setFirstMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {firstMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
      <AccordionItem value="item-2">
        <AccordionTrigger>
          <div className="flex items-center justify-between w-full">
            <p>{secondMessage.title}</p>
            {secondMessageCopied ? (
              <Check />
            ) : (
              <Copy
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard(secondMessage.content);
                  setSecondMessageCopied(true);
                }}
                className="cursor-pointer"
              />
            )}
          </div>
        </AccordionTrigger>
        <AccordionContent>
          {secondMessage.content.split("\n").map((line, index) => (
            <p key={index}>
              {line}
              <br />
            </p>
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default BestPractices;
