import React from "react";

const FreeSeatIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={18} viewBox="0 0 13 12" fill="none">
      <path
        d="M8.7 9.33333L9.8 10.4444L12 8.22222M6.5 7.66667H4.3C3.27493 7.66667 2.76239 7.66667 2.3581 7.83582C1.81903 8.06137 1.39075 8.49397 1.16747 9.03848C1 9.44686 1 9.96457 1 11M8.425 1.16153C9.23125 1.49119 9.8 2.28962 9.8 3.22222C9.8 4.15483 9.23125 4.95325 8.425 5.28291M7.325 3.22222C7.325 4.44952 6.34003 5.44444 5.125 5.44444C3.90997 5.44444 2.925 4.44952 2.925 3.22222C2.925 1.99492 3.90997 1 5.125 1C6.34003 1 7.325 1.99492 7.325 3.22222Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FreeSeatIcon;
