import { Pagination } from "@/lib/interfaces/utils";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Clock3, Trash2 } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import useApiRequest from "../../../lib/hooks/useRequest";
import { capitalizeFirstLetter, formatDateTime } from "../../../lib/hooks/utils";
import { CustomerInvitationListResponseDto } from "../../../lib/interfaces/customers/users";
import { Button } from "../../../ui/components/ui/button";
import Preloader from "../../../ui/components/ui/preloader";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/components/ui/table";

interface DataProps {
  data: CustomerInvitationListResponseDto[];
  setReload: Dispatch<SetStateAction<boolean>>;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  pageCount: number;
  pagination: Pagination;
  isLoading: boolean;
  // globalFilter: string;
  // setGlobalFilter: Dispatch<SetStateAction<string>>;
}

export const InvitationDataTable: React.FC<DataProps> = ({
  data,
  setReload,
  setPagination,
  pageCount,
  pagination,
  isLoading,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const { apiRequest } = useApiRequest();

  const columns: ColumnDef<CustomerInvitationListResponseDto>[] = [
    {
      id: "picture",
      header: "",
      cell: ({ row }) => {
        return (
          <div className={`max-w-[54px] relative min-w-12 `}>
            <img
              src={"/user-default.svg"}
              alt={row.original.firstName}
              width={54}
              height={54}
              className="rounded-full h-14 w-14"
            />
          </div>
        );
      },
    },
    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div className="flex gap-2 items-center">
            <p
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              Name
              {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
            </p>
          </div>
        );
      },
      cell: ({ row }) => {
        const customer = row.original;
        return (
          <div className="flex flex-col text-sm">
            <p className="font-bold">
              {capitalizeFirstLetter(customer.firstName) + " " + capitalizeFirstLetter(customer.lastName)}
            </p>
            <p className="text-[#D0D5DD]">{customer.mail}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "expiresAt",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer min-w-32"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Ablaufdatum
            {/* {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""} */}
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center gap-2 text-sm text-[#D0D5DD]">
            <Clock3 size="18" />
            {formatDateTime(row.original.expiresAt)}
          </div>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer min-w-32"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Eingeladen am
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center gap-2 text-sm text-[#D0D5DD]">
            {formatDateTime(row.original.createdAt)}
          </div>
        );
      },
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        const abortInvitation = async () => {
          await apiRequest(`users/customer/invitations/${row.original._id}`, "DELETE");
          setReload((prev) => !prev);
        };

        return (
          <div className="flex items-center gap-1 max-w-12 mr-2">
            <Button variant="link" className="h-8 w-8 p-0 text-red-500">
              <Trash2 size="20" onClick={abortInvitation} />
            </Button>
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    // globalFilterFn: globalFilterFunction,
    // onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 5 } },
    state: {
      pagination,
      sorting,
      rowSelection,
      // globalFilter,
    },
  });

  return (
    <div className="w-full">
      <div className="rounded-md overflow-hidden">
        {isLoading ? (
          <Preloader />
        ) : (
          <Table className="border-separate border-spacing-y-4">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="text-[#D0D5DD] h-6">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                <>
                  {table.getRowModel().rows.length
                    ? table.getRowModel().rows.map((row, i) => (
                        <TableRow key={row.id} className={` bg-white hover:bg-white`}>
                          {row.getVisibleCells().map((cell, j) => (
                            <TableCell
                              key={cell.id}
                              className={`border-none p-2 ${j === 0 ? "w-24" : ""} ${
                                j === 0 ? "rounded-l-[61px]" : ""
                              } ${j === row.getVisibleCells().length - 1 ? "rounded-r-[61px]" : ""}`}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    : " "}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    Du hast keine aussstehenden Einladungen
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>

      <div className="flex items-center justify-between py-4 my-2">
        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          zurück
        </Button>
        <span className="text-sm text-[#D0D5DD]">
          Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
        </span>

        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          nächste
        </Button>
      </div>
    </div>
  );
};
