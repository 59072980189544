import { InternalUserResponseDto, UserSelectableEntityResponseDto } from "@/lib/interfaces/internal/users";
import { Paginated } from "@/lib/interfaces/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateIcon } from "@radix-ui/react-icons";
import { CloudUpload, UserIcon } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../../lib/hooks/useRequest";
import { parseRoles } from "../../../lib/hooks/utils";
import { UserRole } from "../../../lib/interfaces/user";
import { Button } from "../../../ui/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/components/ui/form";
import { Input } from "../../../ui/components/ui/input";
import Preloader from "../../../ui/components/ui/preloader";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/components/ui/select";
import { Switch } from "../../../ui/components/ui/switch";

const UserEditorDialog = ({
  user,
  setReload,
  onClose,
  endpoint,
}: {
  user: InternalUserResponseDto;
  setReload: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  endpoint: string;
}) => {
  const { apiRequest, apiFileRequest } = useApiRequest();
  const [teamLeaders, setTeamLeaders] = useState<UserSelectableEntityResponseDto[]>();

  const creationEntities = {
    employeeRoles: ["ADMIN", "USER"],
  };

  const fetchTeamLeaders = useCallback(async () => {
    const teamLeaders = await apiRequest<Paginated<UserSelectableEntityResponseDto>>(
      "users/internal/teamLeader",
      "GET"
    );
    if (teamLeaders.data) {
      setTeamLeaders(teamLeaders.data.docs.filter((teamLeader) => teamLeader._id !== user._id));
    }
  }, [apiRequest, user._id]);

  useEffect(() => {
    fetchTeamLeaders();
  }, [fetchTeamLeaders]);

  const addCustomerSchema = z.object({
    firstname: z
      .string()
      .max(45, {
        message: "Der Name darf höchstens 45 Zeichen lang sein",
      })
      .min(1, {
        message: "Vorname ist erforderlich",
      }),
    lastname: z.string().min(1, {
      message: "Nachname ist erforderlich",
    }),
    email: z.string().email("Bitte geben Sie Ihre Email-Adresse ein"),
    employeeRole: z.string().min(1, {
      message: "Rechte sind erforderlich",
    }),
    capacity: z
      .number({
        message: "Kapazität muss eine Zahl zwischen 0 und 7 sein",
      })
      .int()
      .min(0, {
        message: "Kapazität ist erforderlich",
      })
      .max(7, {
        message: "Kapazität darf höchstens 7 sein",
      }),
    belongsToTeamLeaderId: z.string().optional().nullable(),
    isTeamLeader: z.boolean().optional(),
  });

  type AddCustomerSchema = z.infer<typeof addCustomerSchema>;

  const form = useForm<AddCustomerSchema>({
    resolver: zodResolver(addCustomerSchema),
    defaultValues: {
      firstname: user.firstName,
      lastname: user.lastName,
      email: user.mail,
      employeeRole: user.employeeRole,
      isTeamLeader: user.isTeamLeader,
      belongsToTeamLeaderId: user.belongsToTeamLeaderId,
      capacity: user.capacity,
    },
  });

  const onSubmit = async (data: AddCustomerSchema) => {
    const req = {
      firstName: data.firstname,
      lastName: data.lastname,
      mail: data.email,
      ...(user.employeeRole === UserRole.SuperAdmin ? {} : { employeeRole: data.employeeRole }),
      isTeamLeader: data.isTeamLeader,
      belongsToTeamLeaderId:
        data.belongsToTeamLeaderId === "undefined" ? null : data.isTeamLeader ? null : data.belongsToTeamLeaderId,
      capacity: data.capacity,
    };
    await apiRequest(endpoint, "PATCH", {
      body: req,
      toast: { toastText: "Benutzer wurde erfolgreich aktualisiert" },
    });
    setReload((prev) => !prev);
    onClose();
  };

  const [isDragging, setIsDragging] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const handleUpload = (file: File) => {
    setFile(file);
  };

  const handleUploadClick = () => {
    const uploadBtn = document.querySelector(`#upload-chat`) as HTMLButtonElement | null;
    if (uploadBtn) {
      uploadBtn.click();
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      handleFileDrop(event.dataTransfer.files);
    }
  };

  const handleFileDrop = (files: FileList | null) => {
    if (files?.length === 0 || !files) return;

    const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
    const filteredFiles = Array.from(files).filter((file) => allowedTypes.includes(file.type));
    handleUpload(filteredFiles[0]);
  };

  const deleteImage = async () => {
    await apiRequest(`${endpoint}/image`, "DELETE", {
      toast: { toastText: "Profilbild wurde erfolgreich gelöscht" },
    });
    setReload((prev) => !prev);
  };

  const uploadImage = useCallback(async () => {
    await apiFileRequest<string>(`${endpoint}/image`, "POST", file, {
      toast: { toastText: "Profilbild wurde erfolgreich hochgeladen" },
      formdataName: "file",
    });
    setReload((prev) => !prev);
  }, [apiFileRequest, endpoint, file, setReload]);

  useEffect(() => {
    if (file) {
      uploadImage();
    }
  }, [file, uploadImage]);

  return (
    <Dialog onOpenChange={() => onClose()} defaultOpen={true}>
      <DialogContent className="sm:max-w-md min-w-[560px] py-4 px-6 gap-5">
        <DialogHeader className="mb-7">
          <DialogTitle className="flex items-center gap-3">
            <UserIcon />
            <p className="text-[25px] font-bold">Nutzer bearbeiten</p>
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, (e) => console.log(e))} className="flex flex-col gap-5 h-full">
            <FormLabel className="text-[16px] font-bold text-[#12282A]">Name des Benutzers:*</FormLabel>
            <div className="flex gap-3">
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="firstname"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="Vorname" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="lastname"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="Nachname" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">E-Mail:*</FormLabel>
              <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Rolle</FormLabel>
            </div>
            <div className="flex gap-3">
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input {...field} placeholder="E-Mail" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="employeeRole"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Select
                          onValueChange={(value) => {
                            field.onChange(value);
                          }}
                          defaultValue={field.value ?? undefined}
                        >
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Rechte auswählen" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            <SelectItem value="SUPERADMIN" disabled>
                              Super Admin
                            </SelectItem>
                            {creationEntities?.employeeRoles.map((roles) => (
                              <SelectItem key={roles} value={roles}>
                                {parseRoles(roles)}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Team</FormLabel>
            </div>
            <div className="flex gap-3">
              <div className="w-1/2 flex gap-3">
                <p>Teamleiter</p>
                <FormField
                  control={form.control}
                  name="isTeamLeader"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={(value) => {
                            field.onChange(value);
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="w-1/2">
                {form.watch("isTeamLeader") === false && (
                  <>
                    {teamLeaders === undefined && <Preloader />}
                    {teamLeaders && (
                      <>
                        {teamLeaders.length > 0 ? (
                          <FormField
                            control={form.control}
                            name="belongsToTeamLeaderId"
                            render={({ field }) => (
                              <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                                <FormControl>
                                  <Select
                                    onValueChange={(value) => {
                                      field.onChange(value);
                                    }}
                                    defaultValue={field.value ?? undefined}
                                  >
                                    <FormControl>
                                      <SelectTrigger>
                                        <SelectValue placeholder="Teamleiter auswählen" />
                                      </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                      <SelectItem value={"undefined"}>Kein Team</SelectItem>
                                      {teamLeaders.map((teamLeader) => (
                                        <SelectItem key={teamLeader._id} value={teamLeader._id}>
                                          {teamLeader.value}
                                        </SelectItem>
                                      ))}
                                    </SelectContent>
                                  </Select>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        ) : (
                          <p>Kein Teamleiter gefunden</p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex gap-3">
              <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Kapazität</FormLabel>
            </div>
            <div className="flex gap-3">
              <div className="w-1/2">
                <FormField
                  control={form.control}
                  name="capacity"
                  render={({ field }) => (
                    <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                      <FormControl>
                        <Input
                          {...field}
                          placeholder="Kapazität"
                          type="number"
                          onChange={(e) => {
                            field.onChange(parseInt(e.target.value));
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
            {user.profilePictureUrl ? (
              <>
                <img src={user.profilePictureUrl} alt="profile" className="rounded-xl w-32 h-32 object-cover" />
                <Button variant="outline" onClick={deleteImage}>
                  Profilbild löschen
                </Button>
              </>
            ) : (
              <>
                <p className="font-bold leading-9">Profilbild hochladen:</p>
                <div
                  className={`flex flex-col rounded-xl px-6 py-4 border border-[#D0D5DD] text-sm justify-center items-center ${
                    isDragging ? "border-[#C0DE60] border-dotted" : ""
                  }`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  onClick={handleUploadClick}
                >
                  <Button
                    variant="outline"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleUploadClick();
                    }}
                  >
                    <CloudUpload />
                  </Button>
                  <p className="text-sm mt-3">
                    <span className="text-[#C0DE60] font-medium">Zum Upload klicken</span> oder hier reinziehen
                  </p>
                  <p className="text-xs">PDF, SVG, PNG, JPG or GIF (max. 5 MB)</p>
                  <Input
                    type="file"
                    className="hidden"
                    accept="image/png, image/jpeg"
                    id="upload-chat"
                    onChange={(e) => {
                      e.preventDefault();
                      if (e.target?.files && e.target?.files.length > 0) {
                        handleUpload(e.target.files[0]);
                      }
                    }}
                  />
                </div>
              </>
            )}
            <DialogFooter className="sm:justify-start">
              <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
                <UpdateIcon /> <span>User aktualisieren</span>
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default UserEditorDialog;
