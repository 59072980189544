const SemicircularMeter = ({ value, text }: { value: number; text: string }) => {
  const clampedValue = Math.max(0, Math.min(value, 120)); // Ensure value is between 0 and 120

  // Total length of the arc (based on the circumference of a 90-radius semicircle)
  const arcLength = Math.PI * 90; // Circumference of half the circle

  // Calculate the strokeDasharray based on the value
  const filledLength = (clampedValue / 120) * arcLength; // Length of the filled part
  const emptyLength = arcLength - filledLength; // Remaining part of the arc

  return (
    <div className="relative w-48 h-28 flex justify-center items-center">
      {/* Display the percentage */}
      <div className="z-10 self-end text-center">
        <p className="text-3xl font-semibold">{clampedValue}%</p>
        <p className="text-xs">{text}</p>
      </div>

      {/* Background and dynamic arcs */}
      <div className="w-full h-full absolute">
        <svg viewBox="0 -10 200 110" xmlns="http://www.w3.org/2000/svg">
          {/* Background arc */}
          <path
            d="M10,90 A90,90 0 0,1 190,90"
            fill="none"
            stroke="#E5E7EB" // Light gray background
            strokeWidth="20"
            strokeLinecap="round"
          />

          {/* Dynamic arc */}
          <path
            d="M10,90 A90,90 0 0,1 190,90"
            fill="none"
            stroke={
              clampedValue <= 100
                ? `url(#green-semi-color)`
                : clampedValue <= 110
                ? "url(#orange-semi-color)"
                : "url(#red-semi-color)"
            } // Gradient stroke
            strokeWidth="20"
            strokeLinecap="round"
            strokeDasharray={`${filledLength} ${emptyLength}`} // Control the filled part
            strokeDashoffset="0" // Offset to start from the beginning
          />

          {/* Gradient definition */}
          <defs>
            <linearGradient id="green-semi-color" x1="91.5" y1="10" x2="91.5" y2="180" gradientUnits="userSpaceOnUse">
              <stop stopColor="#C0DE60" />
              <stop offset="1" stopColor="#5FD5DE" />
            </linearGradient>
            <linearGradient id="orange-semi-color" x1="91.5" y1="10" x2="91.5" y2="180" gradientUnits="userSpaceOnUse">
              <stop offset="0%" stopColor="#FFBF00" />
              <stop offset="100%" stopColor="#9EB67E" />
            </linearGradient>

            <linearGradient id="red-semi-color" x1="91.5" y1="10" x2="91.5" y2="180" gradientUnits="userSpaceOnUse">
              <stop offset="0%" stopColor="#CE0909" />
              <stop offset="100%" stopColor="#6D0087" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default SemicircularMeter;
