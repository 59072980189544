import {
  AverageRatingGraphResponseDto,
  AverageRatingResponseDto,
  CapacityDataResponseDto,
  OrganizationCapacitySlotDto,
  OrganizationOrEmptyCapacitySlotDto,
  UserCapacityResponseDto,
  UserCapacityResponseUserDto,
  UserTeamLeaderSelectableEntityResponseDto,
} from "@/lib/interfaces/dashboard";
import { Paginated } from "@/lib/interfaces/utils";
import { ArrowDown, ArrowRight, ArrowUp, Crown } from "lucide-react";
import { useEffect, useState } from "react";
import { DateRange } from "react-day-picker";
import useApiRequest from "../../lib/hooks/useRequest";
import { formatDate, formatDateDetail, parseOrganizationStatus } from "../../lib/hooks/utils";
import BankIcon from "../../ui/components/icons/bank-icon";
import ContractIcon from "../../ui/components/icons/contract-icon";
import FreeSeatIcon from "../../ui/components/icons/free-seat-icon";
import ReserveIcon from "../../ui/components/icons/reserve-icon";
import RocketIcon from "../../ui/components/icons/rocket-icon";
import StarIcon from "../../ui/components/icons/star-icon";
import { Button } from "../../ui/components/ui/button";
import DataFetchParent from "../../ui/components/ui/data-fetch";
import { DatePickerWithRange } from "../../ui/components/ui/range-date-picker";
import SearchInput from "../../ui/components/ui/search-input";
import { StarsRating, StarsRatingWhite } from "../../ui/components/ui/stars-rating";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/components/ui/tabs";
import Graph from "./graph";
import SemicircularMeter from "./semicircular-meter";

const Dashboard = () => {
  const { apiRequest } = useApiRequest();
  const [capacity, setCapacity] = useState<CapacityDataResponseDto>();
  const [capacityLoading, setCapacityLoading] = useState<boolean>(false);

  const [teamLeaders, setTeamLeaders] = useState<UserTeamLeaderSelectableEntityResponseDto[]>([]);
  const [teamLeadersLoading, setTeamLeadersLoading] = useState<boolean>(false);

  const [averageRating, setAverageRating] = useState<AverageRatingResponseDto>();
  const [averageRatingLoading, setAverageRatingLoading] = useState<boolean>(false);

  const [averageRatingGraph, setAverageRatingGraph] = useState<AverageRatingGraphResponseDto>();
  const [averageRatingGraphLoading, setAverageRatingGraphLoading] = useState<boolean>(false);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [date, setDate] = useState<DateRange | undefined>();
  const [dateReload, setDateReload] = useState<boolean>(true);

  const getCapacity = async () => {
    setCapacityLoading(true);
    const response = await apiRequest<CapacityDataResponseDto>("team-dashboard/capacity", "GET");
    if (response.data) {
      setCapacity(response.data);
    }
    setCapacityLoading(false);
  };

  const getTeamLeaders = async () => {
    setTeamLeadersLoading(true);
    const response = await apiRequest<Paginated<UserTeamLeaderSelectableEntityResponseDto>>(
      `team-dashboard/team-leaders`,
      "GET"
    );
    if (response.data) {
      setTeamLeaders(response.data.docs);
    }
    setTeamLeadersLoading(false);
  };

  const getAverageRating = async () => {
    setAverageRatingLoading(true);
    const response = await apiRequest<AverageRatingResponseDto>("team-dashboard/average-rating", "GET");
    if (response.data) {
      setAverageRating(response.data);
    }
    setAverageRatingLoading(false);
  };

  const getAverageRatingGraph = async () => {
    setAverageRatingGraphLoading(true);
    let url = "team-dashboard/average-rating-graph?";
    if (date && date.from && date.to) {
      url += `start=${date.from?.toISOString()}&end=${date.to?.toISOString()}`;
    }
    const response = await apiRequest<AverageRatingGraphResponseDto>(url, "GET");
    if (response.data) {
      setAverageRatingGraph(response.data);
      setDate({ from: new Date(response.data.startDate), to: new Date(response.data.endDate) });
      setDateReload(false);
    }
    setAverageRatingGraphLoading(false);
  };

  useEffect(() => {
    getCapacity();
    getTeamLeaders();
    getAverageRating();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dateReload) {
      getAverageRatingGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateReload]);

  return (
    <div className="flex flex-col gap-5 h-full">
      <div className="flex justify-between">
        <div>
          <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">Dashboard</h1>
          <p className="text-[16px] text-[#888]">Übersicht aller Mitarbeiter</p>
        </div>
        <SearchInput setSearchText={setSearchQuery} />
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="w-1/3 bg-white rounded-lg p-4 flex flex-col gap-2">
            <div className="flex justify-between items-center">
              <h5 className="font-semibold">Gesamtzufridenheit</h5>
              <DatePickerWithRange
                setDate={(date) => {
                  setDate(date);
                  if (date?.to && date?.from) {
                    setDateReload(true);
                  }
                }}
                date={date}
                className="w-1/2"
              />
            </div>
            <div className="flex gap-2">
              <DataFetchParent
                isLoading={averageRatingLoading}
                data={averageRating}
                renderElement={(data: AverageRatingResponseDto) => (
                  <div className="flex flex-col gap-2 grow w-full">
                    <div>
                      <p className="text-3xl font-semibold">{data.avgRating?.toFixed(1) ?? 0}/5.0</p>
                    </div>
                    <div>
                      <StarsRating starAmount={data.avgRating ?? 0} />
                    </div>
                    <div>
                      <p className="flex gap-1 text-[#888]">
                        <span
                          className={`flex ${
                            data.avgRatingDifferenceLastMonth > 0
                              ? "text-[#C0DE60]"
                              : data.avgRatingDifferenceLastMonth === 0 || !data.avgRatingDifferenceLastMonth
                              ? "text-[#888]"
                              : "text-[#CE0909]"
                          } items-center gap-1`}
                        >
                          {data.avgRatingDifferenceLastMonth > 0 ? (
                            <ArrowUp size={16} />
                          ) : data.avgRatingDifferenceLastMonth === 0 || !data.avgRatingDifferenceLastMonth ? (
                            <ArrowRight size={16} />
                          ) : (
                            <ArrowDown size={16} />
                          )}
                          {data.avgRatingDifferenceLastMonth ?? 0}%
                        </span>
                        vs Vormonat
                      </p>
                    </div>
                  </div>
                )}
              />
              <div className="grow w-full">
                <DataFetchParent
                  isLoading={averageRatingGraphLoading}
                  data={averageRatingGraph}
                  renderElement={(data: AverageRatingGraphResponseDto) => <Graph data={data} />}
                />
              </div>
            </div>
          </div>
          <div className="bg-white rounded-lg p-4 grow">
            <h5 className="font-semibold">Gesamtauslastung</h5>
            <DataFetchParent
              isLoading={capacityLoading}
              data={capacity}
              renderElement={(data: CapacityDataResponseDto) => (
                <div className="flex gap-4">
                  <div className="flex items-end">
                    <SemicircularMeter
                      value={data.currentCapacityInPercent}
                      text={`Verfügbare Plätze: ${data.futureCapacitySlots[0]?.freeCapacitySlots ?? 0}`}
                    />
                  </div>
                  <div className="w-2/3 grow flex justify-between">
                    <div className="flex justify-evenly flex-col gap-4 grow items-center">
                      <BiWeeklyEmptySeats
                        seats={data.futureCapacitySlots[1].freeCapacitySlots}
                        date={new Date(data.futureCapacitySlots[1].date)}
                      />
                      <BiWeeklyEmptySeats
                        seats={data.futureCapacitySlots[2].freeCapacitySlots}
                        date={new Date(data.futureCapacitySlots[2].date)}
                      />
                    </div>
                    <div className="w-[2px] bg-[#E7E7E7] h-4/5 self-center"></div>
                    <div className="flex justify-evenly flex-col gap-4 grow items-center">
                      <BiWeeklyEmptySeats
                        seats={data.futureCapacitySlots[3].freeCapacitySlots}
                        date={new Date(data.futureCapacitySlots[3].date)}
                      />
                      <BiWeeklyEmptySeats
                        seats={data.futureCapacitySlots[4].freeCapacitySlots}
                        date={new Date(data.futureCapacitySlots[4].date)}
                      />
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div className="w-full bg-white rounded-lg p-4 flex flex-col gap-2">
          <DataFetchParent
            isLoading={teamLeadersLoading}
            data={teamLeaders}
            renderElement={(data: UserTeamLeaderSelectableEntityResponseDto[]) => (
              <OverviewCard teamLeaders={data} searchQuery={searchQuery} />
            )}
          />
        </div>
      </div>
    </div>
  );
};

const OverviewCard = ({
  teamLeaders,
  searchQuery,
}: {
  teamLeaders: UserTeamLeaderSelectableEntityResponseDto[];
  searchQuery: string;
}) => {
  const { apiRequest } = useApiRequest();

  const [userCapacities, setUserCapacities] = useState<UserCapacityResponseUserDto[]>([]);
  const [userCapacitiesLoading, setUserCapacitiesLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const [selectedTeamLeader, setSelectedTeamLeader] = useState<UserTeamLeaderSelectableEntityResponseDto>();

  const getUserCapacities = async () => {
    setUserCapacitiesLoading(true);
    let url = `team-dashboard/user-capacities?page=${page}`;
    if (searchQuery) {
      url += `&search=${searchQuery}`;
      setSelectedTeamLeader(undefined);
    } else if (selectedTeamLeader) {
      url += `&belongsToTeamLeaderId=${selectedTeamLeader._id}`;
    }

    const response = await apiRequest<Paginated<UserCapacityResponseUserDto>>(url, "GET");
    if (response.data) {
      setUserCapacities(response.data.docs);
      setHasMore(response.data.hasNextPage);
    }
    setUserCapacitiesLoading(false);
  };

  useEffect(() => {
    getUserCapacities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTeamLeader, searchQuery]);

  return (
    <Tabs defaultValue="overview" className="w-full" value={selectedTeamLeader?._id ?? "overview"}>
      <TabsList className="gap-4 bg-transparent h-fit p-0 m-0 items-end">
        <OverviewTabsTrigger value="overview" onClick={() => setSelectedTeamLeader(undefined)}>
          <DashboardOverviewIcon />
          <div className="flex flex-col items-start">
            <span>Übersicht</span>
            <span className="text-[#888] font-normal text-sm">Alle Mitarbeiter</span>
          </div>
        </OverviewTabsTrigger>
        {teamLeaders.map((teamLeader, index) => (
          <OverviewTeamLeaderCard
            key={index}
            teamLeader={teamLeader}
            isSuperAdmin={false}
            setSelectedTeamLeader={setSelectedTeamLeader}
          />
        ))}
      </TabsList>
      <TabsContent value="overview">
        <DataFetchParent
          isLoading={userCapacitiesLoading}
          data={userCapacities}
          renderElement={(data: UserCapacityResponseDto[]) => (
            <TabContent data={data} loadMore={() => setPage(page + 1)} hasMore={hasMore} />
          )}
        />
      </TabsContent>
      {teamLeaders.map((teamLeader, index) => (
        <TabsContent key={index} value={teamLeader._id}>
          <DataFetchParent
            isLoading={userCapacitiesLoading}
            data={userCapacities}
            renderElement={(data: UserCapacityResponseDto[]) => (
              <TabContent data={data} loadMore={() => setPage(page + 1)} hasMore={hasMore} />
            )}
          />
        </TabsContent>
      ))}
    </Tabs>
  );
};

const OverviewTeamLeaderCard = ({
  teamLeader,
  isSuperAdmin,
  setSelectedTeamLeader,
}: {
  teamLeader: UserTeamLeaderSelectableEntityResponseDto;
  isSuperAdmin: boolean;
  setSelectedTeamLeader: (teamLeader: UserTeamLeaderSelectableEntityResponseDto) => void;
}) => {
  return (
    <OverviewTabsTrigger value={teamLeader._id} onClick={() => setSelectedTeamLeader(teamLeader)}>
      <div className="relative">
        <img
          src={teamLeader.profilePictureUrl ?? "/user-default.svg"}
          alt={`${teamLeader.firstName} ${teamLeader.lastName}`}
          className="w-[54px] h-[54px] rounded-full"
        />
        {isSuperAdmin && (
          <div
            className={`flex absolute text-white items-center justify-center rounded-full -bottom-1 w-[27px] h-[27px] -right-1 border-[3px] border-white bg-[#12282A]`}
          >
            <Crown size="14" />
          </div>
        )}
      </div>
      <div className="flex flex-col items-start">
        <span>{`${teamLeader.firstName} ${teamLeader.lastName}`}</span>
      </div>
    </OverviewTabsTrigger>
  );
};

const OverviewTabsTrigger = ({
  children,
  value,
  onClick,
}: {
  children: React.ReactNode;
  value: string;
  onClick: () => void;
}) => {
  return (
    <TabsTrigger value={value} className="data-[state=inactive]:opacity-40" onClick={onClick}>
      {children}
    </TabsTrigger>
  );
};

const DashboardOverviewIcon = () => {
  return (
    <svg width={54} height={54} viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={27} cy={27} r={27} fill="#12282A" />
      <path
        d="M21 35V16M35 35V30.25M28 35V23.125"
        stroke="white"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BiWeeklyEmptySeats = ({ seats, date }: { seats: number; date: Date }) => {
  const color = seats < 4 ? (seats < 2 ? "#CE0909" : "#FFBF00") : "#C0DE60";

  return (
    <div className="flex flex-col">
      <div className="text-primary text-lg font-semibold">{formatDate(date.toDateString())}</div>
      <div className="flex items-center gap-1 text-sm" style={{ color }}>
        <svg className="w-2.5 h-2.5" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="50" cy="50" rx="50" ry="50" fill="currentColor" />
        </svg>
        <span>
          {seats} {seats === 1 ? "Freier Platz" : "Freie Plätze"}
        </span>
      </div>
    </div>
  );
};

const TabContent = ({
  data,
  loadMore,
  hasMore,
}: {
  data: UserCapacityResponseDto[];
  loadMore: () => void;
  hasMore: boolean;
}) => {
  return (
    <div className="flex gap-4 overflow-x-auto p-2">
      {data.map((member, index) => (
        <MemberCard key={index} user={member.user} tasks={member.slots} />
      ))}
      {hasMore && (
        <div className="flex justify-center items-center">
          <Button onClick={loadMore}>Load more</Button>
        </div>
      )}
    </div>
  );
};

const MemberCard = ({
  user,
  tasks,
}: {
  user: UserCapacityResponseUserDto;
  tasks: OrganizationOrEmptyCapacitySlotDto[];
}) => {
  return (
    <div className="min-w-72 flex flex-col gap-4">
      <div className="flex justify-between">
        <div className="flex gap-2 items-center">
          <img
            src={user.profilePictureUrl ?? "/user-default.svg"}
            alt={`${user.firstName} ${user.lastName}`}
            className="w-[33px] h-[33px] rounded-full"
          />
          <h5 className="font-semibold">{`${user.firstName} ${user.lastName}`}</h5>
        </div>
        <div className="grow flex justify-between h-fit self-center">
          <div></div>
          <StarsRating starAmount={user.avgRating} />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        {tasks.map((task, index) => (
          <TaskCard key={index} organization={task.organization} type={task.isEmpty ? "empty" : "normal"} />
        ))}
        {tasks.length <= user.capacity && <TaskCard type="reserved" />}
      </div>
    </div>
  );
};

const TaskCard = ({
  organization,
  type,
}: {
  organization?: OrganizationCapacitySlotDto;
  type: "normal" | "empty" | "reserved";
}) => {
  return (
    <div
      className={`flex flex-col min-h-16 bg-white rounded-full shadow-custom p-2 px-4 ${
        organization?.isOverCapacity ? "opacity-60 text-red-500" : ""
      }`}
    >
      {organization && type === "normal" ? (
        <>
          <p className="text-sm truncate">{organization?.name}</p>
          <div className="flex justify-between">
            <div className="grow">
              <StarsRatingWhite starAmount={organization?.rating ?? 0} />
            </div>
            <StatusField status={organization?.belongsToStatus ?? ""} statusDate={organization?.statusDate} />
          </div>
        </>
      ) : (
        <div className="flex items-start justify-center h-full flex-col">
          <div className="flex w-full grow justify-center items-center gap-2 font-semibold">
            {type === "empty" ? (
              <>
                <FreeSeatIcon />
                <p>Freier Platz</p>
              </>
            ) : (
              <>
                <ReserveIcon />
                <p>Reserve</p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const StatusField = ({ status, statusDate }: { status: string; statusDate?: string }) => {
  const { text, color, icon } = parseOrganizationStatus(status);
  let iconElement;

  switch (icon) {
    case "star":
      iconElement = <StarIcon />;
      break;
    case "rocket":
      iconElement = <RocketIcon />;
      break;
    case "contract":
      iconElement = <ContractIcon />;
      break;
    case "bank":
      iconElement = <BankIcon />;
      break;
    default:
      iconElement = <ContractIcon />;
      break;
  }

  const colorStyle = {
    borderColor: color,
    color: color,
    backgroundColor: `${color}33`, // Hex with 20% opacity
  };

  return (
    <div className="flex justify-end items-center text-[8px]">
      <div
        className="border w-fit rounded-2xl px-[0.175rem] py-[0.175rem] font-bold flex gap-1 items-center"
        style={colorStyle}
      >
        <div className="scale-[0.7]">{iconElement}</div>
        <p>
          {status === "NEW_CUSTOMER_DATE" || status === "CANCELLATION_DATE" ? (
            <>
              {text} - {formatDateDetail(statusDate ?? "")}
            </>
          ) : (
            text
          )}
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
