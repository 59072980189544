import React from "react";

enum StarFill {
  NONE,
  HALF,
  FULL,
}

const StarSvg = ({ fillOpacity }: { fillOpacity: number }) => {
  return (
    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
      <path
        d="M4.73911 7.5861L7.0375 8.97621C7.45842 9.23098 7.97348 8.85437 7.86271 8.37808L7.2535 5.76399L9.28606 4.00281C9.65712 3.68159 9.45774 3.07238 8.97037 3.03361L6.29537 2.80654L5.24863 0.336452C5.06033 -0.112151 4.41788 -0.112151 4.22958 0.336452L3.18284 2.801L0.507839 3.02807C0.0204682 3.06684 -0.178911 3.67605 0.192156 3.99727L2.22471 5.75846L1.6155 8.37254C1.50473 8.84883 2.0198 9.22544 2.44071 8.97067L4.73911 7.5861Z"
        fill="currentColor"
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

// Make mask IDs unique for each instance
const HalfStarSvgWithId = ({ id }: { id: string }) => {
  const maskId = `halfStarMask-${id}`;
  return (
    <svg viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
      <defs>
        <mask id={maskId}>
          <rect x="0" y="0" width="10" height="10" fill="white" />
          <rect x="5" y="0" width="5" height="10" fill="black" />
        </mask>
      </defs>
      {/* Background star with low opacity */}
      <path
        d="M4.73911 7.5861L7.0375 8.97621C7.45842 9.23098 7.97348 8.85437 7.86271 8.37808L7.2535 5.76399L9.28606 4.00281C9.65712 3.68159 9.45774 3.07238 8.97037 3.03361L6.29537 2.80654L5.24863 0.336452C5.06033 -0.112151 4.41788 -0.112151 4.22958 0.336452L3.18284 2.801L0.507839 3.02807C0.0204682 3.06684 -0.178911 3.67605 0.192156 3.99727L2.22471 5.75846L1.6155 8.37254C1.50473 8.84883 2.0198 9.22544 2.44071 8.97067L4.73911 7.5861Z"
        fill="currentColor"
        fillOpacity="0.3"
      />
      {/* Foreground star with full opacity and mask */}
      <path
        d="M4.73911 7.5861L7.0375 8.97621C7.45842 9.23098 7.97348 8.85437 7.86271 8.37808L7.2535 5.76399L9.28606 4.00281C9.65712 3.68159 9.45774 3.07238 8.97037 3.03361L6.29537 2.80654L5.24863 0.336452C5.06033 -0.112151 4.41788 -0.112151 4.22958 0.336452L3.18284 2.801L0.507839 3.02807C0.0204682 3.06684 -0.178911 3.67605 0.192156 3.99727L2.22471 5.75846L1.6155 8.37254C1.50473 8.84883 2.0198 9.22544 2.44071 8.97067L4.73911 7.5861Z"
        fill="currentColor"
        mask={`url(#${maskId})`}
      />
    </svg>
  );
};

const StarsRating = ({ starAmount }: { starAmount: number }) => {
  // Clamp and round starAmount to the nearest 0.5 between 0 and 5
  const clampedStars = Math.max(0, Math.min(Math.round(starAmount * 2) / 2, 5));
  const id = React.useId();

  return (
    <div className="bg-primary text-white flex p-1 max-w-24 gap-1 rounded-full px-2 w-full">
      {[...Array(5)].map((_, index) => {
        const starFill =
          clampedStars > index ? (clampedStars > index + 0.5 ? StarFill.FULL : StarFill.HALF) : StarFill.NONE;

        return starFill === StarFill.HALF ? (
          <HalfStarSvgWithId key={index} id={`${id}-${index}`} />
        ) : (
          <StarSvg key={index} fillOpacity={index < clampedStars ? 1 : 0.3} />
        );
      })}
    </div>
  );
};

const StarsRatingWhite = ({ starAmount }: { starAmount: number }) => {
  // Clamp and round starAmount to the nearest 0.5 between 0 and 5
  const clampedStars = Math.max(0, Math.min(Math.round(starAmount * 2) / 2, 5));
  const id = React.useId();

  return (
    <div className="text-primary flex p-1 max-w-24 gap-1 rounded-full px-2">
      {[...Array(5)].map((_, index) => {
        const starFill =
          clampedStars > index ? (clampedStars > index + 0.5 ? StarFill.FULL : StarFill.HALF) : StarFill.NONE;

        return starFill === StarFill.HALF ? (
          <HalfStarSvgWithId key={index} id={`${id}-${index}`} />
        ) : (
          <StarSvg key={index} fillOpacity={index < clampedStars ? 1 : 0.3} />
        );
      })}
    </div>
  );
};

export { StarsRating, StarsRatingWhite };
