import { Mail, MoveRight } from "lucide-react";
import { Button } from "../../../ui/components/ui/button";
import { Input } from "../../../ui/components/ui/input";

import { zodResolver } from "@hookform/resolvers/zod";
import useApiRequest from "../../../lib/hooks/useRequest";
import { Form, FormControl, FormField, FormItem, FormMessage } from "../../../ui/components/ui/form";

// import { InvitationStatusEnum, IUser } from "@/app/interfaces/user";
// import { apiRequest } from "@/lib/serverFetch";
import { CustomerInvitationListResponseDto } from "@/lib/interfaces/customers/users";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useNavigate } from "react-router-dom";

// Define your Zod schema
const formSchema = z.object({
  firstName: z.string().min(1, {
    message: "Vorname ist erforderlich",
  }),
  lastName: z.string().min(1, {
    message: "Nachname ist erforderlich",
  }),
  mail: z
    .string()
    .min(1, {
      message: "Email ist erforderlich",
    })
    .email({
      message: "Ungültige E-Mail-Adresse",
    }),
});

type FormSchema = z.infer<typeof formSchema>;

const UsersPage = () => {
  const { apiRequest } = useApiRequest();
  const [invitedUsers, setInvitedUsers] = useState<CustomerInvitationListResponseDto[]>();
  const navigate = useNavigate();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      mail: "",
    },
  });

  const onSubmit = async (data: FormSchema) => {
    // Handle form submission here
    const req = {
      firstName: data.firstName,
      lastName: data.lastName,
      mail: data.mail,
    };
    await apiRequest("users/customer/invitations/onboarding", "POST", {
      body: req,
      toast: {
        toastText: "Einladung wurde erfolgreich gesendet",
      },
    });
    getInvitedUsers();
    form.reset();
  };

  const finsihOnboardingStage = async () => {
    const res = await apiRequest("organizations/customer/finishOnboardingAddUsersStage", "PATCH", {
      toast: {
        toastText: "Schritt erfolgreich abgeschlossen",
      },
    });
    if (res.status >= 200 && res.status < 300) {
      navigate("/register/video");
    }
  };

  const getInvitedUsers = useCallback(async () => {
    const res = await apiRequest<CustomerInvitationListResponseDto[]>("users/customer/invitations/onboarding", "GET");
    if (res.data) {
      setInvitedUsers(res.data);
    }
  }, [apiRequest]);

  useEffect(() => {
    getInvitedUsers();
  }, [getInvitedUsers]);

  return (
    <div className="flex flex-col h-full justify-around items-center mx-32">
      <div className="bg-white p-9 rounded-2xl flex flex-col items-center justify-between border border-[#D0D5DD] min-w-[686px]">
        <h2 className="text-[32px] font-bold text-center leading-9">Zusätzlichen Benutzer hinzufügen (optional)</h2>
        <p className="text-[#888] font-bold text-center">Füge weitere Benutzer hinzu</p>

        <div className="flex gap-4 mb-10 mt-16 w-full">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex gap-5 h-full">
              <div className="flex w-1/4">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem className="flex flex-col w-full">
                      <FormControl>
                        <Input {...field} placeholder="Vorname" className="w-full" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex w-1/4">
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormControl>
                        <Input {...field} placeholder="Nachname" className="w-full" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex w-1/4">
                <FormField
                  control={form.control}
                  name="mail"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormControl>
                        <Input {...field} type="email" placeholder="E-Mail" className="w-full" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="flex w-1/4">
                <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-2 w-full">
                  <Mail size="20" />
                  <span>Einladung senden</span>
                </Button>
              </div>
            </form>
          </Form>
        </div>
        <div className="flex flex-col gap-3 max-h-[340px] overflow-y-scroll custom-scrollbar w-full">
          {!invitedUsers ? (
            <p>Loading...</p>
          ) : (
            invitedUsers.map((user) => {
              return (
                <div key={user._id} className="flex gap-4 mb-3 w-full font-semibold text-[#888]">
                  <div className="flex flex-col w-1/4 pl-4">
                    <p>{user.firstName}</p>
                  </div>
                  <div className="flex flex-col w-1/4 pl-4">
                    <p>{user.lastName}</p>
                  </div>
                  <div className="flex flex-col w-1/4 px-4 max-w-1/4 overflow-hidden">
                    <p className="text-ellipsis overflow-hidden">{user.mail}</p>
                  </div>
                </div>
              );
            })
          )}
        </div>
        {/* <Link href="/register/onboarding" className="w-full mt-6"> */}
        <Button
          onClick={finsihOnboardingStage}
          type="button"
          variant="default"
          className="text-white font-bold flex items-center gap-2 hover:gap-3 w-full"
        >
          <span>Weiter</span>
          <MoveRight size="20" />
        </Button>
        {/* </Link> */}
      </div>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex items-center text-xl font-bold">
          <div className="w-1/2 relative">
            <div className="absolute top-4 left-5 custom-gradient w-full h-2"></div>
            <div className="flex relative z-20 justify-center items-center text-white bg-[#12282A] w-10 h-10 rounded-full">
              1
            </div>
          </div>
          <div className="w-1/2 relative">
            <div className="absolute top-4 left-5 bg-white w-full h-2"></div>
            <div className="flex relative z-20 justify-center items-center  text-white bg-[#12282A] w-10 h-10 rounded-full">
              2
            </div>
          </div>
          <div className="">
            <div className="flex relative z-20 justify-center items-center text-[#CCCCCC] text-xl bg-white w-10 h-10 rounded-full">
              3
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between text-sm font-semibold">
          <div className="text-[#CCCCCC] w-24">Daten</div>
          {/* <div className="text-[#CCCCCC] w-32 text-center">Zahlungsmethode</div> */}
          <div className="w-32 text-center">Benutzer</div>
          <div className="text-[#CCCCCC] w-24 text-right">Onboarding</div>
        </div>
      </div>
    </div>
  );
};

export default UsersPage;
