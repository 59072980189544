import { Pagination } from "@/lib/interfaces/utils";
import { EyeClosedIcon, EyeOpenIcon } from "@radix-ui/react-icons";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Clock3, Crown, LockKeyhole } from "lucide-react";
import { Dispatch, SetStateAction, useState } from "react";
import UserEditorDialog from "../../../components/order/customer/user-editor";
import useApiRequest from "../../../lib/hooks/useRequest";
import { capitalizeFirstLetter, formatDateTime } from "../../../lib/hooks/utils";
import { InternalUserListResponseDto, InternalUserResponseDto } from "../../../lib/interfaces/internal/users";
import { UserRole } from "../../../lib/interfaces/user";
import { Button } from "../../../ui/components/ui/button";
import Preloader from "../../../ui/components/ui/preloader";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../ui/components/ui/table";
import { StarsRating } from "../../../ui/components/ui/stars-rating";

interface DataProps {
  data: InternalUserListResponseDto[];
  setReload: Dispatch<SetStateAction<boolean>>;
  setPagination: Dispatch<SetStateAction<Pagination>>;
  pageCount: number;
  pagination: Pagination;
  isLoading: boolean;
  // globalFilter: string;
  // setGlobalFilter: Dispatch<SetStateAction<string>>;
}

export const UserDataTable: React.FC<DataProps> = ({
  data,
  setReload,
  setPagination,
  pageCount,
  pagination,
  isLoading,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const { apiRequest } = useApiRequest();
  const [user, setUser] = useState<InternalUserResponseDto>();

  const columns: ColumnDef<InternalUserListResponseDto>[] = [
    {
      id: "picture",
      header: "",
      cell: ({ row }) => {
        return (
          <div className={`max-w-[54px] relative min-w-12 `}>
            <img
              src={row.original.profilePictureUrl ?? "/user-default.svg"}
              alt={row.original.firstName}
              className="rounded-full h-14 w-14 object-cover"
            />
            {row.original.employeeRole === UserRole.SuperAdmin && (
              <div
                className={`flex absolute text-white items-center justify-center rounded-full -bottom-1 w-[27px] h-[27px] -right-1 border-[3px] border-white bg-[#12282A]`}
              >
                <Crown size="14" />
              </div>
            )}
          </div>
        );
      },
    },

    {
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <div className="flex gap-2 items-center">
            <p
              className="flex justify-center items-center gap-1 cursor-pointer"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              Name
              {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
            </p>
          </div>
        );
      },
      cell: ({ row }) => {
        const customer = row.original;
        return (
          <div className="flex flex-col text-sm">
            <p className="font-bold">
              {capitalizeFirstLetter(customer.firstName) + " " + capitalizeFirstLetter(customer.lastName)}
            </p>
            <p className="text-[#888] font-medium">Rechte: {customer.employeeRole}</p>
          </div>
        );
      },
    },
    {
      accessorKey: "date",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer min-w-32"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Letzter Login
            {/* {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""} */}
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center gap-2 text-sm text-[#D0D5DD]">
            <Clock3 size="18" />
            {formatDateTime(row.original.lastLogin)}
          </div>
        );
      },
    },
    {
      accessorKey: "rating",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer min-w-32"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Bewertung
            {/* {column.getIsSorted() ? (column.getIsSorted() === "desc" ? " 🔽" : " 🔼") : ""} */}
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center gap-2 text-sm text-[#D0D5DD]">
            <StarsRating starAmount={row.original.rating} />
          </div>
        );
      },
    },
    {
      accessorKey: "customerCount",
      header: ({ column }) => {
        return (
          <p
            className="flex justify-center items-center gap-1 cursor-pointer"
            onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          >
            Kunden
            {/* {column.getIsSorted() === "desc" ? <ChevronDown size="18" /> : <ChevronUp size="18" />} */}
          </p>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="flex justify-center items-center text-sm">
            <div className="border border-[#D0D5DD] min-w-24 text-center rounded-2xl px-3 py-1 font-bold">
              <p>{row.original.customerCount ?? 0}</p>
            </div>
          </div>
        );
      },
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }) => {
        if (row.original.employeeRole === UserRole.Admin || row.original.employeeRole === UserRole.SuperAdmin) {
          return <LockKeyhole size="20" className="ml-1" />;
        }

        const archiveToggle = async () => {
          await apiRequest(
            `users/internal/${row.original._id}/${row.original.isArchive ? "unarchive" : "archive"}`,
            "PATCH",
            {
              toast: {
                toastText: `Benutzer ${row.original.isArchive ? "wiederhergestellt" : "archiviert"}`,
              },
            }
          );
          setReload((prev) => !prev);
        };

        return (
          <div className="flex items-center gap-1 max-w-12 mr-2">
            <Button variant="link" className="h-8 w-8 p-0" onClick={archiveToggle}>
              {row.original.isArchive ? <EyeClosedIcon className="h-5 w-5" /> : <EyeOpenIcon className="h-5 w-5" />}
            </Button>
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data,
    columns,
    pageCount,
    manualPagination: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    // onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    // onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    // globalFilterFn: globalFilterFunction,
    // onGlobalFilterChange: setGlobalFilter,
    initialState: { pagination: { pageSize: 5 } },
    state: {
      pagination,
      sorting,
      rowSelection,
      // globalFilter,
    },
  });

  const fetchUser = async (id: string) => {
    const res = await apiRequest<InternalUserResponseDto>(`users/internal/${id}`, "GET");
    if (res.data) {
      setUser(res.data);
    }
  };

  return (
    <div className="w-full">
      <div className="rounded-md overflow-hidden">
        {isLoading ? (
          <Preloader />
        ) : (
          <Table className="border-separate border-spacing-y-4">
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="text-[#D0D5DD] h-6">
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                <>
                  {table.getRowModel().rows.length
                    ? table.getRowModel().rows.map((row, i) => (
                        <TableRow
                          key={row.id}
                          className={` bg-white hover:bg-white ${
                            row.original.isArchive ? "opacity-50" : ""
                          } cursor-pointer`}
                          onClick={() => fetchUser(row.original._id)}
                        >
                          {row.getVisibleCells().map((cell, j) => (
                            <TableCell
                              key={cell.id}
                              className={`border-none p-2 ${j === 0 ? "w-24" : ""} ${
                                j === 0 ? "rounded-l-[61px]" : ""
                              } ${j === row.getVisibleCells().length - 1 ? "rounded-r-[61px]" : ""}`}
                            >
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))
                    : " "}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    Du hast noch keine Benutzer angelegt
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </div>

      <div className="flex items-center justify-between py-4 my-2">
        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          zurück
        </Button>
        <span className="text-sm text-[#D0D5DD]">
          Seite {table.getState().pagination.pageIndex + 1} von {table.getPageCount()}
        </span>

        <Button
          className="text-[17px] text-[#888] hover:text-[#12282A]"
          variant="outline"
          size="sm"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          nächste
        </Button>
      </div>
      {user && (
        <UserEditorDialog
          user={user}
          setReload={setReload}
          onClose={() => setUser(undefined)}
          endpoint={`users/internal/${user._id}`}
        />
      )}
    </div>
  );
};
