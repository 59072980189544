import { useCallback, useContext } from "react";
import { toast } from "sonner";
import { Context, ErrorEntity, Error as ErrorI } from "../../components/root/context";

// Interface for options that can be passed to the apiRequest function
interface ApiRequestOptions {
  // Optional toast configuration
  toast?: { toastText?: string };
  // Optional body for the request
  body?: any;
  test?: boolean;
}

// Interface for the response returned by the apiRequest function
interface ApiResponse<T> {
  data: T | undefined;
  error: Error | unknown | undefined;
  status: number;
}

type ApiFileRequestOptions = {
  formdataName: string;
  customFields?: { [key: string]: string };
  toast?: {
    toastText?: string;
  };
};


// Custom hook to create an API request function
const useApiRequest = () => {
  const { setError, accessToken } = useContext(Context);
  // useCallback to memoize the apiRequest function
  const apiRequest = useCallback(
    async <T>(
      route: string, // API route
      method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE", // HTTP method
      options?: ApiRequestOptions // Optional request options
    ): Promise<ApiResponse<T>> => {
      // Setting up the fetch options

      const fetchOptions: RequestInit = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          "authorization": `Bearer ${accessToken}`
        },
      };

      // If a body is provided, add it to the fetch options
      if (options?.body) {
        fetchOptions.body = JSON.stringify(options.body);
      }

      // Create a promise for the fetch response
      const responsePromise = new Promise<{ data: T; status: number }>(async (resolve, reject) => {
        try {
          const url = options?.test ? `${process.env.REACT_APP_API}/api/${route}` : `${process.env.REACT_APP_API}/v1.0/${route}`;
          // Fetching data from the API - will be replaced in the future with the api url via env variables
          const res = await fetch(url, fetchOptions);

          //console.log(res);

          // If the response is not ok, reject the promise with an error
          if (!res.ok) {
            const error = await getErrorMessage(res);
            if (setError) {
              setError(error);
            }

            reject({
              error: new Error("Request failed"),
              message: error,
              status: res.status,
            });
            return;
          }

          // Try to parse the response as JSON
          let responseData: T;
          try {
            responseData = await res.json();
          } catch {
            // If parsing fails, reject the promise with an error
            const x = "success" as unknown as T;
            resolve({ data: x, status: res.status });
            return;
          }

          // Resolve the promise with the parsed data
          resolve({
            data: responseData,
            status: res.status,
          });
        } catch (error) {
          // Log the error and reject the promise
          reject({ error, status: 500 });
        }
      });

      // If toast options are provided, display a toast notification
      if (options?.toast) {
        // toast.promise(responsePromise, {
        //   loading: "Loading...", // Message while loading
        //   success: options.toast.toastText || "Success!", // Success message
        //   error: (err) => (err instanceof Error ? err.message : "An error occurred"), // Error message
        // });
        toast.promise(responsePromise, {
          loading: "Loading...", // Message while loading
          success: options.toast.toastText || "Success!", // Success message
          error: (err) => (err instanceof Error ? err.message : "Es ist ein Fehler aufgetreten"), // Error message
        });
      }

      // Wait for the response and handle the result
      try {
        const data = await responsePromise;
        return { data: data.data, error: undefined, status: data.status }; // Return the data if successful
      } catch (errorCatched) {
        const error = errorCatched as unknown as {
          message: string;
          error: Error | unknown;
          status: number;
        };
        return { data: undefined, error: error.message, status: error.status }; // Return the error if failed
      }
    },
    [accessToken, setError]
  );

  const apiFileRequest = useCallback(
    async <T>(
      route: string,
      method: "POST" | "DELETE" | "GET" | "PUT" | "PATCH",
      file?: any,
      options?: ApiFileRequestOptions
    ): Promise<ApiResponse<T>> => {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken}`);

      const formdata = new FormData();
      if (file) {
        if (options?.formdataName) {
          if (Array.isArray(file)) {
            file.forEach((f, i) => {
              formdata.append(options.formdataName, f, f.name);
            });
          } else {
            formdata.append(options.formdataName, file, file.name);
          }
        } else {
          formdata.append(
            Array.isArray(file) ? "files" : "file",
            Array.isArray(file) ? file[0] : file,
            file.name
          );
        }
      }
      if (options?.customFields) {
        Object.keys(options.customFields).forEach((key) => {
          formdata.append(key, options.customFields ? options.customFields[key] : "");
        });
      }

      const requestOptions: RequestInit = {
        method: method,
        headers: myHeaders,
        body: formdata,
      };

      const responsePromise = new Promise<{ data: T; status: number }>(async (resolve, reject) => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API}/v1.0/${route}`, requestOptions);

          if (!response.ok) {
            const error = await getErrorMessage(response);
            if (setError) {
              setError(error);
            }
            reject({
              error: new Error("Request failed"),
              message: error,
              status: response.status,
            });
            return;
          }

          let responseData: T;
          try {
            responseData = await response.json();
          } catch {
            reject({ error: new Error("Failed to parse JSON"), message: "Failed to parse JSON", status: response.status });
            return;
          }

          resolve({
            data: responseData,
            status: response.status,
          });
        } catch (error) {
          reject({ error, status: 500 });
        }
      });

      if (options?.toast) {
        toast.promise(responsePromise, {
          loading: "Loading...",
          success: options.toast.toastText || "Success!",
          error: (err) => (err instanceof Error ? err.message : "Es ist ein Fehler aufgetreten"),
        });
      }

      try {
        const data = await responsePromise;
        return { data: data.data, error: undefined, status: data.status };
      } catch (errorCatched) {
        const error = errorCatched as unknown as {
          message: string;
          error: Error | unknown;
          status: number;
        };
        if (error.status > 200 && error.status < 299) {
          toast.success("File erfolgreich hochgeladen");
          return { data: undefined, error: undefined, status: error.status };
        }
        toast.error("Es gab leider ein Problem. Bitte versuche es später wieder oder kontaktiere den Support");
        return { data: undefined, error: error.message, status: error.status };
      }
    },
    [accessToken, setError]
  );

  return { apiRequest, apiFileRequest }; // Return the apiRequest function
};

const getErrorMessage = async (res: Response) => {
  let errorMessage = "";
  let errorLocation = "";
  let error: ErrorI;
  let isClosable = true;
  let withReload = false;
  let title = "Es ist ein Fehler aufgetreten";
  let redirect = false;

  try {
    error = (await res.json()) as ErrorI;
  } catch {
    throw new Error("Failed to parse JSON");
  }

  if (!error) {
    throw new Error("Failed to parse JSON");
  }

  if (res.status === 400) {
    errorMessage = "400 - Scheinbar scheinen Ihre Angaben nicht ganz korrekt zu sein. Bitte korrigiere das und versuche es nochmals.";
  } else if (res.status === 409) {
    errorMessage = "409 - Scheinbar gab es einen Konflikt. Bitte versuche es später nochmals oder kontaktiere den Support.";
  } else if (res.status === 403) {
    errorMessage = "403 - Du scheinst nicht die nötigen Rechte zu haben. Bitte kontaktiere den Support oder Ihren Organisationsadministrator.";
  } else if (res.status === 500) {
    errorMessage = "500 - Es ist ein Fehler aufgetreten, der nicht auftreten soll. Bitte schreibe dem Support. - Zeitpunkt: " + new Date().toLocaleString();
  } else if (res.status === 404) {
    errorMessage = "404 - das Objekt wurde nicht gefunden. Bitte versuche es später nochmals oder kontaktiere den Support";
  } else {
    errorMessage = `${res.status} - Es ist ein Fehler aufgetreten. Bitte versuche es später nochmals oder kontaktiere den Support`;
  }

  if (error.error === ErrorEntity.NoOrganizationForbidden) {
    errorLocation = "/register/informations";
    error.entity = ErrorEntity.NoOrganizationForbidden;
    redirect = true;
  } else if (error.error === ErrorEntity.IncompleteOnboardingUserInvitationsForbidden) {
    errorLocation = "/register/users";
    error.entity = ErrorEntity.IncompleteOnboardingUserInvitationsForbidden;
    redirect = true;
  } else if (error.error === ErrorEntity.IncompleteOnboardingIntroductionVideoForbidden) {
    errorLocation = "/register/video";
    error.entity = ErrorEntity.IncompleteOnboardingIntroductionVideoForbidden;
    redirect = true;
  } else if (error.error === ErrorEntity.EMailNotVerifiedForbidden) {
    errorLocation = "/verification/mail";
    error.entity = ErrorEntity.EMailNotVerifiedForbidden;
    redirect = true;
  } else if (error.error === ErrorEntity.Conflict) {
    error.entity = ErrorEntity.Conflict;
    if (error.message.includes("No organizations found - please add some")) {
      errorMessage = "Es scheint, als hätten Sie noch keine Organisationen. Bitte fügen Sie eine hinzu.";
    } else if (error.message === "Final URL is required for status DONE") {
      errorMessage = "Für den Status 'Erledigt' wird ein Link zu den finalen Dateien benötigt.";
    } else if (error.message === "Files can only be uploaded to new jobs") {
      errorMessage = "Dateien können nur bei nicht gestarteten Aufträgen hochgeladen werden."
    } else if (error.message === "Files can only be deleted from new jobs") {
      errorMessage = "Dateien können nur bei nicht gestarteten Aufträgen gelöscht werden."
    } else if (error.message === "Organization already exists") {
      errorMessage = "Diese Organisation existiert bereits.";
    } else if (error.message === "User already exists") {
      errorMessage = "Der Benutzer existiert bereits.";
    } else if (error.message === "User already has an invitation") {
      errorMessage = "Der Benutzer hat bereits eine Einladung.";
    } else if (error.message === "User or Organization Name already has an invitation") {
      errorMessage = "Der Nutzer oder der Organisationsname wurde bereits eingeladen.";
    } else if (error.message === "Organization is already cancelled") {
      errorMessage = "Die Organisation wurde bereits gekündigt.";
    } else if (error.message === "Job is already done") {
      errorMessage = "Der Auftrag ist bereits abgeschlossen, deshalb ist diese Aktion nicht verfügbar";
    } else if (error.message === "Rating already exists") {
      errorMessage = "Ein Rating für diesen Auftrag existiert bereits.";
    } else if (error.message === "User is archived") {
      errorMessage = "Der Benutzer ist archiviert.";
    } else if (error.message === "Team leader cannot be archived") {
      errorMessage = "Der Teamleiter kann nicht archiviert werden.";
    } else if (error.message === "Team leader cannot be changed as long as he has a team") {
      errorMessage = "Der Teamleiter kann nicht geändert werden, solange er ein Team hat.";
    } else {
      errorMessage = "Ein Konflikt ist aufgetreten. Bitte versuche es später nochmals oder kontaktiere den Support.";
    }
  } else if (error.error === ErrorEntity.NoUserFoundForbidden) {
    error.entity = ErrorEntity.NoUserFoundForbidden;
    errorMessage = "Leider ist uns der angemeldete Nutzer nicht bekannt! Bitte kontaktiere einen Administrator, um Zugriff auf das Tool zu erhalten.";
    isClosable = false;
    errorLocation = "/login";
  } else if (error.message === "One Filename already exists") {
    errorMessage = "Eine Datei mit diesem Namen existiert bereits. Bitte benenne die Datei um und versuche es nochmals.";
  } else if (error.error === ErrorEntity.PaymentOverdueForbidden) {
    error.entity = ErrorEntity.PaymentOverdueForbidden;
    errorMessage = "Die Organisation wurde mit einer überfälligen Zahlung markiert. Bitte führe diese durch und / oder wende dich an einen Administrator.";
    isClosable = false;
  } else if (error.message === "Diese Aktion ist leider nicht möglich, es gibt keinen anderen Auftrag mit dem Status neu in die Richtung in die du tauschen möchtest!") {
    errorMessage = "Dieser Job kann nicht in diese Richtung verschoben werden.";
  } else if (error.message === "Only not started jobs can be moved") {
    errorMessage = "Nur Aufträge mit dem Status neu können getauscht werden!";
  } else if (error.message === "One Filename already exists") {
    errorMessage = "Einer der Dateinamen existiert bereits.";
  } else if (error.message === "No job found to move") {
    errorMessage = "Der Auftrag ist in diese Richtung nicht verschiebbar, da es keinen Auftrag gibt, mit dem die Position getauscht werden kann.";
  } else if (error.message === "Job not found") {
    errorMessage = "Der Auftrag wurde nicht gefunden.";
  } else if (error.message === "Rating message not found") {
    errorMessage = "Die Bewertungsnachricht wurde nicht gefunden.";
  }

  const parsedError: ErrorI = {
    message: errorMessage,
    error: error.error,
    statusCode: res.status,
    entity: error.entity,
    location: {
      path: errorLocation,
      redirect,
    },
    isClosable,
    withReload,
    title,
    mail: error.mail,
  };

  return parsedError
}

export default useApiRequest;
