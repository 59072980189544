import { InternalInvitationCreationEntitiesResponseDto } from "@/lib/interfaces/internal/users";
import { zodResolver } from "@hookform/resolvers/zod";
import { Mail, UserIcon } from "lucide-react";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import useApiRequest from "../../../lib/hooks/useRequest";
import { parseRoles } from "../../../lib/hooks/utils";
import AddIcon from "../../../ui/components/icons/add-icon";
import { Button } from "../../../ui/components/ui/button";
import DataFetchParent from "../../../ui/components/ui/data-fetch";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../../ui/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../ui/components/ui/form";
import { Input } from "../../../ui/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/components/ui/select";

const AddInternalUserFormParent = ({ setReload }: { setReload: Dispatch<SetStateAction<boolean>> }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [creationEntities, setCreationEntities] = useState<InternalInvitationCreationEntitiesResponseDto>();
  const { apiRequest } = useApiRequest();
  const [open, setOpen] = useState(false);

  const fetchCreationEntities = useCallback(async () => {
    if (!open) return;
    setIsLoading(true);
    const res = await apiRequest<InternalInvitationCreationEntitiesResponseDto>(
      "users/internal/invitations/creation-entities",
      "GET"
    );
    setIsLoading(false);
    if (res.data) {
      setCreationEntities(res.data);
    }
  }, [apiRequest, open]);

  useEffect(() => {
    fetchCreationEntities();
  }, [fetchCreationEntities]);

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <Button className="gap-4" onClick={() => setOpen(true)}>
        <AddIcon />
        <span>Neuer Mitarbeiter</span>
      </Button>
      <DialogContent className="sm:max-w-md min-w-[560px] py-4 px-6 gap-5">
        <DialogHeader className="mb-7">
          <DialogTitle className="flex items-center gap-3">
            <UserIcon />
            <p className="text-[25px] font-bold">Neuen Mitarbeiter hinzufügen</p>
          </DialogTitle>
        </DialogHeader>

        <DataFetchParent
          data={creationEntities}
          renderElement={(data) => (
            <AddInternalUserForm setReload={setReload} creationEntities={data} setOpen={setOpen} />
          )}
          isLoading={isLoading}
        />
      </DialogContent>
    </Dialog>
  );
};

const AddInternalUserForm = ({
  setReload,
  setOpen,
  creationEntities,
}: {
  setReload: Dispatch<SetStateAction<boolean>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  creationEntities: InternalInvitationCreationEntitiesResponseDto;
}) => {
  const { apiRequest } = useApiRequest();

  const addCustomerSchema = z.object({
    firstname: z
      .string()
      .max(45, {
        message: "Der Name darf höchstens 45 Zeichen lang sein",
      })
      .min(1, {
        message: "Vorname ist erforderlich",
      }),
    lastname: z.string().min(1, {
      message: "Nachname ist erforderlich",
    }),
    email: z.string().email("Bitte geben Sie Ihre Email-Adresse ein"),
    role: z.string().min(1, {
      message: "Rechte sind erforderlich",
    }),
  });

  type AddCustomerSchema = z.infer<typeof addCustomerSchema>;

  const form = useForm<AddCustomerSchema>({
    resolver: zodResolver(addCustomerSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      role: "",
    },
  });

  const onSubmit = async (data: AddCustomerSchema) => {
    const req = {
      firstName: data.firstname,
      lastName: data.lastname,
      mail: data.email,
      employeeRole: data.role,
    };
    await apiRequest("users/internal/invitations", "POST", {
      body: req,
      toast: { toastText: "Benutzer wurde erfolgreich eingeladen" },
    });
    setOpen(false);
    setReload((prev) => !prev);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full">
        <FormLabel className="text-[16px] font-bold text-[#12282A]">Name des Benutzers:*</FormLabel>
        <div className="flex gap-3">
          <div className="w-1/2">
            <FormField
              control={form.control}
              name="firstname"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                  <FormControl>
                    <Input {...field} placeholder="Vorname" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="w-1/2">
            <FormField
              control={form.control}
              name="lastname"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                  <FormControl>
                    <Input {...field} placeholder="Nachname" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <div className="flex gap-3">
          <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">E-Mail:*</FormLabel>
          <FormLabel className="text-[16px] font-bold text-[#12282A] w-1/2">Rechte</FormLabel>
        </div>
        <div className="flex gap-3">
          <div className="w-1/2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                  <FormControl>
                    <Input {...field} placeholder="E-Mail" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="w-1/2">
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem className="flex flex-col bg-white rounded-lg gap-[14px]">
                  <FormControl>
                    <Select
                      onValueChange={(value) => {
                        field.onChange(value);
                      }}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="gap-3 h-10 items-center rounded-2xl px-3 py-2 border text-sm inline-flex justify-center whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground">
                          <SelectValue placeholder="Rechte auswählen" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {creationEntities?.employeeRoles.map((roles) => (
                          <SelectItem key={roles} value={roles}>
                            {parseRoles(roles)}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </div>
        <DialogFooter className="sm:justify-start">
          {/* <DialogClose asChild>
                <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
                  <Mail /> <span>Einladung senden</span>
                </Button>
              </DialogClose> */}
          <Button type="submit" variant="default" className="text-white font-bold flex items-center gap-4 w-full">
            <Mail /> <span>Einladung senden</span>
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default AddInternalUserFormParent;
