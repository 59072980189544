import { AverageRatingGraphResponseDto } from "@/lib/interfaces/dashboard";
import { Area, AreaChart, DotProps, ResponsiveContainer, Tooltip, TooltipProps } from "recharts";
import { formatDate } from "../../lib/hooks/utils";

// Custom Tooltip Component
const CustomTooltip = ({ active, payload }: TooltipProps<any, any>) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-primary text-white p-2 rounded-lg text-xs">
        <p style={{ margin: 0 }}>
          <strong className="">{payload[0].payload.name}:</strong> {payload[0].payload.value.toFixed(1)}
        </p>
      </div>
    );
  }
  return null;
};

const CustomDot = (props: DotProps) => {
  const { cx, cy } = props; // Get the x and y coordinates
  if (!cx || !cy) return null;

  return (
    <g>
      {/* Outer translucent circle */}
      <circle
        cx={cx}
        cy={cy}
        r={11} // Radius for outer circle
        fill="none"
        stroke="#12282A" // Black stroke color
        strokeWidth={2}
        opacity={0.2} // Translucent effect
      />
      {/* Inner gradient-filled circle */}
      <defs>
        <linearGradient id="dotGradient" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#C0DE60" />
          <stop offset="100%" stopColor="#5FD5DE" />
        </linearGradient>
      </defs>
      <circle
        cx={cx}
        cy={cy}
        r={5} // Radius for inner circle
        fill="url(#dotGradient)" // Gradient fill
      />
      {/* Black border for inner circle */}
      <circle cx={cx} cy={cy} r={5} fill="none" stroke="#12282A" strokeWidth={2} />
    </g>
  );
};

const Graph = ({ data }: { data: AverageRatingGraphResponseDto }) => {
  const parsedData = data.monthlyRatings.map((rating) => ({
    name: `${formatDate(rating.startDate)} - ${formatDate(rating.endDate)}`,
    value: rating.avgRating ?? 0,
  }));

  return (
    <ResponsiveContainer width="100%" height={100}>
      <AreaChart data={parsedData}>
        {/* Gradient Definitions */}
        <defs>
          <linearGradient id="fillGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#C0DE60" stopOpacity={0.4} />
            <stop offset="100%" stopColor="#5FD5DE" stopOpacity={0} />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient id="strokeGradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#C0DE60" stopOpacity={1} />
            <stop offset="100%" stopColor="#5FD5DE" stopOpacity={1} />
          </linearGradient>
        </defs>
        {/* Area with Border on Top */}
        <Area
          type="linear"
          dataKey="value"
          stroke="url(#strokeGradient)"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#fillGradient)"
          activeDot={<CustomDot />} // Custom Dot
        />
        {/* Custom Tooltip */}
        <Tooltip content={<CustomTooltip />} cursor={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default Graph;
