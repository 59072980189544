import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import BestPractices from "./components/best-practices/page";
import Customers from "./components/customers/page";
import PageNotFound from "./components/error/page-not-found";
import Help from "./components/help/page";
import OrderDetailsParent from "./components/order/[id]/page";
import CreateOrder from "./components/order/create/page";
import Order from "./components/order/page";
import OrganisationDetailParent from "./components/organisations/[id]/page";
import Organisations from "./components/organisations/page";
import RegisterInformationsParent from "./components/register/informations/page";
import Logout from "./components/register/logout/page";
import Register from "./components/register/page";
import ResetPassword from "./components/register/reset-password";
import RegisterUsers from "./components/register/users/page";
import VideoScreen from "./components/register/video/page";
import Settings from "./components/settings/page";
import Support from "./components/support/page";
import { MailVerification } from "./components/verification/mail-verification";
import { useUser } from "./hooks/use-user";
import Preloader from "./ui/components/ui/preloader";
import Dashboard from "./components/dashboard/page";

const Routes = () => {
  const [element, setElement] = useState(<Preloader />);
  const { isAuthenticated, user, isLoading } = useAuth0();
  const [isProfileComplete, setProfileComplete] = useState(false);
  const { user: loggedInUser } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      setElement(<Preloader />);
      return;
    }
    if (!isAuthenticated) {
      return;
    }
    setProfileComplete(true);
  }, [isAuthenticated, isLoading, navigate, user]);

  const getElement = (route: string, element: JSX.Element, title: string) => {
    if (!loggedInUser) {
      return <Preloader />;
    }
    return element;
  };

  const routes = useRoutes([
    {
      path: "/collaborators",
      element: isProfileComplete ? getElement("/collaborators", <Customers />, "die Kunden") : element,
    },
    { path: "/help", element: isProfileComplete ? getElement("/help", <Help />, "die Hilfe") : element },
    {
      path: "/order/create",
      element: isProfileComplete ? getElement("/order/create", <CreateOrder />, "die Bestellung") : element,
    },
    {
      path: "/order/:id",
      element: isProfileComplete ? getElement("/order/:id", <OrderDetailsParent />, "die Bestellung") : element,
    },
    {
      path: "/",
      element: isProfileComplete ? getElement("/", <Order />, "die Bestellung") : element,
    },
    {
      path: "/register/informations",
      element: <RegisterInformationsParent />,
    },
    {
      path: "/register/users",
      element: <RegisterUsers />,
    },
    {
      path: "/register/video",
      element: <VideoScreen />,
    },
    {
      path: "/settings",
      element: isProfileComplete ? getElement("/settings", <Settings />, "die Einstellungen") : element,
    },
    {
      path: "/organisations",
      element: isProfileComplete ? getElement("/organisations", <Organisations />, "die Organisationen") : element,
    },
    {
      path: "/organisations/:id",
      element: isProfileComplete
        ? getElement("/organisations/:id", <OrganisationDetailParent />, "die Organisationen")
        : element,
    },
    {
      path: "/7i7-best-practices",
      element: isProfileComplete ? getElement("/7i7-best-practices", <BestPractices />, "die Best Practices") : element,
    },
    {
      path: "/dashboard",
      element: isProfileComplete ? getElement("/dashboard", <Dashboard />, "das Dashboard") : element,
    },
    { path: "/logout", element: <Logout /> },
    { path: "/verification/mail", element: <MailVerification /> },
    { path: "/support", element: <Support /> },
    { path: "/reset", element: <ResetPassword /> },
    { path: "/register", element: <Register /> },
    { path: "/*", element: <PageNotFound /> },
  ]);

  return routes;
};

export default Routes;
