import React, { useContext } from "react";

import { Auth0Provider } from "@auth0/auth0-react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";
import Sidebar from "../navigation/sidebar";
import { Context, ContextProvider } from "./context";
import ErrorPage from "./error-page";

const AuthProvider = ({ children }: any) => {
  const redirectUri = window.location.origin;

  if (
    !(
      process.env.REACT_APP_AUTH0_DOMAIN &&
      process.env.REACT_APP_AUTH0_ID &&
      process.env.REACT_APP_AUTH0_AUDIENCE &&
      redirectUri
    )
  ) {
    return null;
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        scope: "openid profile email offline_access",
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        ui_locales: "de",
      }}
    >
      {children}
    </Auth0Provider>
  );
};

const RootWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <AuthProvider>
      <ContextProvider>
        <Wrapper>{children}</Wrapper>
      </ContextProvider>
    </AuthProvider>
  );
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { isMobile } = useContext(Context);

  const excludeSidebar = [
    "/register/informations",
    "/register/users",
    "/register/video",
    "/reset",
    "/verification/mail",
    "/verification/2fa",
  ];

  const location = useLocation();

  const margin = !excludeSidebar.includes(location.pathname);

  try {
    return (
      <div className={`flex gap-4 w-full h-full min-h-[100vh] ${isMobile ? "flex-col" : ""}`}>
        {!excludeSidebar.includes(location.pathname) && <Sidebar />}
        {isMobile ? (
          <div className={"min-h-[100vh] w-full p-4"}>{children}</div>
        ) : (
          <div
            className={
              (margin ? "ml-28 p-14 flex flex-col flex-grow min-h-screen" : "") +
              " min-h-[100vh] max-w-[calc(100%-7em)]"
            }
          >
            {children}
          </div>
        )}
      </div>
    );
  } catch (error) {
    return <ErrorPage />;
  }
};

export default RootWrapper;
