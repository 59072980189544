"use client";

import * as React from "react";
import { DateRange } from "react-day-picker";
import { formatDate } from "../../../lib/hooks/utils";
import { cn } from "../../../lib/utils/cn";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
  setDate: (date: DateRange | undefined) => void;
  date: DateRange | undefined;
}

export function DatePickerWithRange({ className, setDate, date }: DatePickerWithRangeProps) {
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn("justify-start text-left font-normal", !date && "text-muted-foreground")}
          >
            {date?.from ? (
              date.to ? (
                <>
                  {formatDate(date.from.toISOString())} - {formatDate(date.to.toISOString())}
                </>
              ) : (
                formatDate(date.from.toISOString())
              )
            ) : (
              <span>Wähle ein Zeitraum</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={new Date()}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
            disabled={{ after: new Date() }}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
