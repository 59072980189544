import { JobListCustomerResponseDto } from "@/lib/interfaces/customers/jobs";
import {
  JobCreationEntitiesInternalResponseDto,
  JobCreationEntitiesInternalResponseDtoWithFoundOrganization,
} from "@/lib/interfaces/internal/jobs";
import { zodResolver } from "@hookform/resolvers/zod";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { LoaderCircle } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import DataFetchParent from "../../../..//ui/components/ui/data-fetch";
import useApiRequest from "../../../../lib/hooks/useRequest";
import { convertHtmlToEditorState } from "../../../../lib/hooks/utils";
import AddIcon from "../../../../ui/components/icons/add-icon";
import { Button } from "../../../../ui/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../../../../ui/components/ui/form";
import { Input } from "../../../../ui/components/ui/input";
import Preloader from "../../../../ui/components/ui/preloader";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../ui/components/ui/select";
import CreateEditor from "../../create/create-editor";
import DataUpload from "../../create/data-upload";

export const CreateJob = () => {
  const { apiRequest } = useApiRequest();
  const [selectableOrganizations, setSelectableOrganizations] = useState<JobCreationEntitiesInternalResponseDto>();
  const [loading, setLoading] = useState(true);

  const getSelectableOrganizations = useCallback(async () => {
    setLoading(true);
    const res = await apiRequest<JobCreationEntitiesInternalResponseDto>("jobs/internal/creation-entities", "GET");
    if (res.data) {
      setSelectableOrganizations(res.data);
    }
    setLoading(false);
  }, [apiRequest]);

  useEffect(() => {
    getSelectableOrganizations();
  }, [getSelectableOrganizations]);

  return (
    <DataFetchParent
      data={selectableOrganizations}
      isLoading={loading}
      renderElement={(data) => <CreateJobForm selectableOrganizations={data} />}
    />
  );
};

const CreateJobForm = ({
  selectableOrganizations,
}: {
  selectableOrganizations: JobCreationEntitiesInternalResponseDto;
}) => {
  const { apiRequest } = useApiRequest();
  const [selectableCustomerUsers, setSelectableCustomerUsers] =
    useState<JobCreationEntitiesInternalResponseDtoWithFoundOrganization>();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string>();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() => convertHtmlToEditorState(""));
  const [submitting, setSubmitting] = useState(false);

  const formSchema = z.object({
    name: z
      .string()
      .max(45, {
        message: "Der Name darf höchstens 45 Zeichen lang sein",
      })
      .min(1, {
        message: "Name ist erforderlich",
      }),
    description: z.string().refine(
      () => {
        const htmlContentProfile = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        return htmlContentProfile.length > 2;
      },
      { message: "Beschreibung ist erforderlich" }
    ),
    belongsToOrganizationId: z.string().min(1, {
      message: "Organisation ist erforderlich",
    }),
    belongsToCustomerUserId: z.string().min(1, {
      message: "Kunde ist erforderlich",
    }),
  });

  type FormSchema = z.infer<typeof formSchema>;

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      description: "",
      belongsToOrganizationId: "",
      belongsToCustomerUserId: "",
    },
  });

  const onSubmit = async (data: FormSchema) => {
    setSubmitting(true);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const htmlContent = draftToHtml(rawContentState);
    const req = {
      title: data.name,
      description: htmlContent,
      belongsToOrganizationId: data.belongsToOrganizationId,
      belongsToCustomerUserId: data.belongsToCustomerUserId,
    };
    const res = await apiRequest<JobListCustomerResponseDto>("jobs/internal", "POST", {
      body: req,
      toast: {
        toastText: "Auftrag erfolgreich erstellt",
      },
    });
    if (res) {
      setId(res.data?._id);
    }
  };

  const getSelectableCustomerUsers = useCallback(
    async (organizationId: string) => {
      setLoading(true);
      const res = await apiRequest<JobCreationEntitiesInternalResponseDtoWithFoundOrganization>(
        `jobs/internal/creation-entities/${organizationId}`,
        "GET"
      );
      if (res.data) {
        setSelectableCustomerUsers(res.data);
      }
      setLoading(false);
    },
    [apiRequest]
  );

  const onFilesUploaded = () => {
    setSubmitting(false);
    navigate(`/order/${id}`);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-5 h-full">
        {/* <div className="flex flex-col gap-5 h-full"> */}
        <div className="flex justify-between">
          <div className="w-1/3">
            <h1 className="text-[32px] leading-[38px] font-bold text-[#12282A]">Neuer Auftrag</h1>
            <p className="text-[16px] text-[#888]">Erstelle einen neuen Auftrag</p>
          </div>
          <div className="w-full flex gap-4">
            <div className="w-1/4">
              <FormField
                control={form.control}
                name="belongsToOrganizationId"
                render={({ field }) => (
                  <FormItem className="">
                    <div className="bg-white rounded-lg">
                      <FormControl className="flex flex-col gap-[14px]">
                        <Select
                          onValueChange={(value) => {
                            field.onChange(value);
                            getSelectableCustomerUsers(value);
                          }}
                          defaultValue={field.value}
                        >
                          <FormControl>
                            <SelectTrigger className="gap-3 h-10 items-center rounded-2xl px-3 py-2 border text-sm inline-flex justify-center whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground">
                              <SelectValue placeholder="Kunde" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {selectableOrganizations?.organizations.map((organization) => (
                              <SelectItem key={organization._id} value={organization._id}>
                                {organization.value}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            {selectableCustomerUsers && (
              <div className="w-1/4">
                <FormField
                  control={form.control}
                  name="belongsToCustomerUserId"
                  render={({ field }) => (
                    <FormItem className="">
                      <div className="bg-white rounded-lg">
                        <FormControl className="flex flex-col gap-[14px]">
                          <Select
                            onValueChange={(value) => {
                              field.onChange(value);
                            }}
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger className="gap-3 h-10 items-center rounded-2xl px-3 py-2 border text-sm inline-flex justify-center whitespace-nowrap font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border-input bg-background hover:bg-accent hover:text-accent-foreground">
                                <SelectValue placeholder="Ansprechpartner" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {selectableCustomerUsers.customerUsers.map((customerUser) => (
                                <SelectItem key={customerUser._id} value={customerUser._id}>
                                  {customerUser.value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                      </div>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            )}
            {loading && <Preloader />}
          </div>
          <Button type="submit" className="flex gap-4" disabled={submitting}>
            {submitting ? <LoaderCircle className="animate-spin" /> : <AddIcon />}
            <span>Auftrag einreichen</span>
          </Button>
        </div>
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem className="flex flex-col py-7 px-9 bg-white rounded-lg gap-[14px]">
              <FormLabel className="text-[16px] font-bold text-[#12282A]">
                Name des Auftrages* <span className="font-normal text-[#888]">(max. 45 Zeichen)</span>
              </FormLabel>
              <FormControl>
                <Input
                  {...field}
                  placeholder="Trage hier einen Namen für Deinen Auftrag ein."
                  className="w-full placeholder:text-[#888]"
                  onChange={(e) => {
                    field.onChange(e);
                    if (e.target.value.length > 45) {
                      form.setError("name", {
                        type: "max",
                        message: "Der Name darf höchstens 45 Zeichen lang sein",
                      });
                    } else {
                      form.clearErrors("name");
                    }
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="description"
          render={({ field }) => (
            <FormItem className="flex flex-col py-7 px-9 bg-white rounded-lg gap-[14px]">
              <FormLabel className="text-[16px] font-bold text-[#12282A]">Auftragsbeschreibung*</FormLabel>
              <FormControl>
                <div className="w-full">
                  <FormControl>
                    <CreateEditor editorState={editorState} setEditorState={setEditorState} />
                  </FormControl>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DataUpload type="internal" onFilesUploaded={onFilesUploaded} id={id} />
      </form>
    </Form>
  );
};
