import React from "react";

const ReserveIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={21} viewBox="0 0 11 14" fill="none">
      <path
        d="M2.35065 5.42105H2.28571V4.15789C2.28571 2.41384 3.7248 1 5.5 1C7.2752 1 8.71429 2.41384 8.71429 4.15789V5.42105H8.64935M5.5 7.94737V9.21053M10 8.57895C10 11.0206 7.98528 13 5.5 13C3.01472 13 1 11.0206 1 8.57895C1 6.13727 3.01472 4.15789 5.5 4.15789C7.98528 4.15789 10 6.13727 10 8.57895Z"
        stroke="currentColor"
        strokeOpacity="0.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReserveIcon;
