import { type ClassValue, clsx } from "clsx";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const capitalizeFirstLetter = (string: string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

export const formatTimeSpent = (startDate: string): string => {
  const start = new Date(startDate);
  const now = new Date();

  const diff = now.getTime() - start.getTime();

  const minutes = Math.floor(diff / (1000 * 60));
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const years = Math.floor(diff / (1000 * 60 * 60 * 24 * 365));

  if (years > 0) {
    return `${years} Jahr${years > 1 ? "e" : ""}`;
  } else if (days > 0) {
    return `${days} Tag${days > 1 ? "e" : ""}`;
  } else if (hours > 0) {
    return `${hours} Std.${hours > 1 ? "" : ""}`;
  } else {
    return `${minutes} Minute${minutes > 1 ? "n" : ""}`;
  }
};

export const formatMonthYearDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    // Handle invalid date
    return "Invalid Date";
  }
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short" };
  return date.toLocaleDateString("en-US", options);
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    // Handle invalid date
    return "Invalid Date";
  }
  const day = String(date.getDate()).padStart(2, "0"); // Ensure day is two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure month is two digits
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

export const formatDateDetail = (dateString: string): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    // Handle invalid date
    return "Invalid Date";
  }
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("de", options);
};

export const formatDateTime = (dateString: string): string => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    // Handle invalid date
    return "Kein Login";
  }
  const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "short", day: "numeric", hour: "numeric", minute: "numeric" };
  return date.toLocaleDateString("de", options);
};


export const downloadURI = (uri: string, name: string) => {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.target = "_blank";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const parseRoles = (role: string) => {
  switch (role) {
    case "ADMIN":
      return "Administrator";
    case "USER":
      return "Mitarbeiter";
    case "SUPER_ADMIN":
      return "Super Administrator";
    default:
      return role;
  }
}

export const parseOrganizationStatus = (status: string) => {
  switch (status) {
    case "NEW_CUSTOMER_DATE":
      return {
        text: "Neukunde",
        color: "#5FD5DE",
        icon: "star",
      };
    case "ACTIVE":
      return {
        text: "Aktiv",
        color: "#C0DE60",
        icon: "rocket",
      };
    case "CANCELLATION_DATE":
      return {
        text: "In Kündigung",
        color: "#CE0909",
        icon: "contract",
      };
    case "CANCELLED":
      return {
        text: "Gekündigt",
        color: "#CE0909",
        icon: "contract",
      };
    case "PAYMENT_OVERDUE":
      return {
        text: "Zahlung überfällig",
        color: "#FC9701",
        icon: "bank",
      };
    case "DEACTIVATED_BY_ADMIN":
      return {
        text: "Vom Admin deaktiviert",
        color: "#CE0909",
        icon: "contract",
      };
    default:
      return {
        text: "Status nicht gefunden",
        color: "#CE0909",
        icon: "contract",
      };
  }
};

export const parseStatus = (status: string) => {
  switch (status) {
    case "ACTIVE":
      return "Aktiv";
    case "INACTIVE":
      return "Inaktiv";
    case "PENDING":
      return "In Bearbeitung";
    case "NEW":
      return "Neu";
    case "FEEDBACK":
      return "Feedback";
    case "DONE":
      return "Erledigt";
    default:
      return status;
  }
}

export const convertHtmlToEditorState = (html: string): EditorState => {
  let processedHtml;
  if (html.includes("<p>")) {
    processedHtml = html.replaceAll('&#8205;', "").replaceAll(`\n<p></p>\n`, '<p>&#8205;</p>');
  } else {
    processedHtml = html.replaceAll('&#8205;', "").replaceAll(`\n\n`, '<p>&#8205;</p>');
    if (processedHtml.includes("\n")) {
      let finalHtml = "";
      processedHtml.split("\n").forEach((line) => {
        finalHtml += `<p>${line}</p>`;
      });
      processedHtml = finalHtml;
    }
  }
  const blocksFromHTML = convertFromHTML(processedHtml);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(contentState);
};


export const convertHtmlToContentState = (html: string) => {
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    return ContentState.createFromBlockArray(contentBlock.contentBlocks);
  }
  return ContentState.createFromText('');
};